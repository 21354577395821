/**
 *
 * Navbar
 *
 */

import React, { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { FormattedMessage } from "react-intl";
import messages from "./messages";

function Navbar() {
  const navigate = useNavigate();
  return (
    <div>
      {/* <FormattedMessage {...messages.header} /> */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
        <a
          href="javascript:void(0)"
          onClick={() => navigate("/")}
          className="navbar-brand d-flex align-items-center px-4 px-lg-5"
        >
          <h2 className="m-0 text-primary">
            <i className="fa fa-book me-3"></i>QList
          </h2>
        </a>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            {/* <Link to={"/"}>
              <a className="nav-item nav-link">Home</a>
            </Link> */}
            <a
              href="javascript:void(0)"
              className="nav-item nav-link"
              onClick={() => navigate("/")}
            >
              Home
            </a>
            {/* <a className="nav-item nav-link active">Home</a> */}
            <a
              href="javascript:void(0)"
              onClick={() => navigate("/AboutUs")}
              className="nav-item nav-link"
            >
              About
            </a>
            <div className="nav-item dropdown">
              <a
                href="javascript:void(0)"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                English Grammar
              </a>
              <div className="dropdown-menu fade-down m-0">
                <a href="/Articles-Guide" className="dropdown-item">
                  Articles
                </a>
                <a href="/Gender-Guide" className="dropdown-item">
                  Gender
                </a>
                <a href="/Punctuation-Guide" className="dropdown-item">
                  Punctuation
                </a>
                <a href="/Vocabulary-Guide" className="dropdown-item">
                  Vocabulary
                </a>
                <a href="/Sentence-Guide" className="dropdown-item">
                  Sentence
                </a>
                <a href="/Nouns-Guide" className="dropdown-item">
                  Nouns
                </a>
                <a href="/Adjectives-Guide" className="dropdown-item">
                  Adjectives
                </a>
              </div>
            </div>
            <div className="nav-item dropdown">
              <a
                href="javascript:void(0)"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                West Bengal
              </a>
              <div className="dropdown-menu fade-down m-0">
                <a href="/West-Bengal-Class-12-Guide" className="dropdown-item">
                  উচ্চমাধ্যমিক ( Higher Secondary )
                </a>
                <a href="/West-Bengal-Class-10-Guide" className="dropdown-item">
                  মাধ্যমিক ( Madhyamik )
                </a>
                <a href="/West-Bengal-Class-9-Guide" className="dropdown-item">
                  ক্লাস ৯ ( Class 9 )
                </a>
                <a href="/West-Bengal-Class-8-Guide" className="dropdown-item">
                  ক্লাস ৮ ( Class 8 )
                </a>
                {/* <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.isbawb"
                  className="dropdown-item"
                >
                  ক্লাস ৭ <i className="fa fa-mobile text-primary"></i>
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.isbawb"
                  className="dropdown-item"
                >
                  ক্লাস ৬ <i className="fa fa-mobile text-primary"></i>
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.isbawb"
                  className="dropdown-item"
                >
                  ক্লাস ৫ <i className="fa fa-mobile text-primary"></i>
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.isbawb"
                  className="dropdown-item"
                >
                  ক্লাস ৪ <i className="fa fa-mobile text-primary"></i>
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.isbawb"
                  className="dropdown-item"
                >
                  ক্লাস ৩ <i className="fa fa-mobile text-primary"></i>
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.isbawb"
                  className="dropdown-item"
                >
                  ক্লাস ২ <i className="fa fa-mobile text-primary"></i>
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.isbawb"
                  className="dropdown-item"
                >
                  ক্লাস ১ <i className="fa fa-mobile text-primary"></i>
                </a> */}
                {/* <a
                  href="javascript:void(0)"
                  onClick={() => navigate("/BaseLine-Guide")}
                  className="dropdown-item"
                >
                  Base Line
                </a> */}
              </div>
            </div>
            {/* <a href="courses.html" className="nav-item nav-link">
              Courses
            </a> */}
            {/* <div className="nav-item dropdown">
              <a
                href="javascript:void(0)"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Olympiad
              </a>
              
            </div> */}
            <a href="/AboutUs" className="nav-item nav-link">
              Contact
            </a>
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=com.isbawb"
            className="btn btn-primary py-4 px-lg-5 d-none d-lg-block"
          >
            Join Now<i className="fa fa-arrow-right ms-3"></i>
          </a>
        </div>
      </nav>
    </div>
  );
}

Navbar.propTypes = {};

export default memo(Navbar);
