import logo from "./logo.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Outlet,
  Link,
} from "react-router-dom";

import Header from "./components/HeaderComponent";
import AboutUsPage from "./components/AboutComponent";
import NavbarPage from "./components/NavbarComponent";
import ClassBaseLinePage from "./containers/BaseLine";
import HomePage from "./containers/HomePage";
import Class10Page from "./containers/ClassX";
import Class9Page from "./containers/ক্লাস৯";

import EnglishPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/Loadable";
import Class10FathersHelpPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/FathersHelp/Loadable";
import Class10FablePage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/Fable/Loadable";
import Class10ThePassingAwayofBapuPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/ThePassingAwayofBapu/Loadable";
import Class10MyOwnTrueFamilyPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/MyOwnTrueFamily/Loadable";
import Class10OurRunawayKitePage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/OurRunawayKite/Loadable";
import Class10SeaFeverPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/SeaFever/Loadable";
import Class10TheCatPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/TheCat/Loadable";
import Class10TheSnailPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/TheSnail/Loadable";
import Class10ParagraphPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/Paragraph/Loadable";
import Class10ProcessingFlowChartPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/ProcessingFlowChart/Loadable";
import Class10ReportPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/Report/Loadable";
import Class10LetterWritingPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/LetterWriting/Loadable";
import Class10NOTICEWRITINGPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/NOTICEWRITING/Loadable";
import Class10StoryWritingPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/StoryWriting/Loadable";
import Class10ArticlesandPrepositionPage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/ArticlesandPreposition/Loadable";
import Class10VoicechangePage from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/Voicechange/Loadable";
import Class10Previousyearquestionpaper2017Page from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/Previousyearquestionpaper2017/Loadable";
import Class10Previousyearquestionpaper2018Page from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/Previousyearquestionpaper2018/Loadable";
import Class10Previousyearquestionpaper2019Page from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/Previousyearquestionpaper2019/Loadable";
import Class10Previousyearquestionpaper2020Page from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/Previousyearquestionpaper2020/Loadable";
import Class10Previousyearquestionpaper2023Page from "./containers/ClassX/English-মাধ্যমিকপ্রস্তুতি/Previousyearquestionpaper2023/Loadable";

// import Class10Page from "./containers/ClassX";
// import EnglishPage from "./containers/ClassX/Englishমাধ্যমিকপ্রস্তুতি";
// import FathersHelpPage from "./containers/ClassX/Englishমাধ্যমিকপ্রস্তুতি/FathersHelp";
// import FablePage from "./containers/ClassX/Englishমাধ্যমিকপ্রস্তুতি/Fable";
// import ThePassingAwayofBapuPage from "./containers/ClassX/Englishমাধ্যমিকপ্রস্তুতি/ThePassingAwayofBapu";
// import MyOwnTrueFamilyPage from "./containers/ClassX/Englishমাধ্যমিকপ্রস্তুতি/MyOwnTrueFamily";
// import ParagraphPage from "./containers/ClassX/Englishমাধ্যমিকপ্রস্তুতি/Paragraph";
// import ArticlesandPrepositionPage from "./containers/ClassX/Englishমাধ্যমিকপ্রস্তুতি/ArticlesandPreposition";
// import Previousyearquestionpaper2023Page from "./containers/ClassX/Englishমাধ্যমিকপ্রস্তুতি/Previousyearquestionpaper2023";

//Bengali
import BengaliPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/Loadable";
// import অভিষেকPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/অভিষেক";
// import অসুখীএকজনPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/অসুখীএকজন";
// import আফ্রিকাPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/আফ্রিকা";
// import আয়আরোবেঁধেবেঁধেথাকিPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/আয়আরোবেঁধেবেঁধেথাকি";
// import জ্ঞানচক্ষুPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/জ্ঞানচক্ষু";
// import প্রবন্ধরচনাPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/প্রবন্ধরচনা";
// import প্রলয়োল্লাসPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/প্রলয়োল্লাস";
// import বহুরূপীPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/বহুরূপী";
// import বাচ্যপরিবর্তনPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/বাচ্যপরিবর্তন";
// import সিরাজদৌল্লাPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/সিরাজদৌল্লা";
// import হারিয়েযাওয়াকালিকলমPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/হারিয়েযাওয়াকালিকলম";
// import Class10বাংলামাধ্যমিকপ্রস্তুতিPage from "./containers/ClassX/বাংলামাধ্যমিকপ্রস্তুতি";
import Class10জ্ঞানচক্ষুPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/জ্ঞানচক্ষু/Loadable";
import Class10অসুখীএকজনPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/অসুখীএকজন/Loadable";
import Class10আয়আরোবেঁধেবেঁধেথাকিPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/আয়আরোবেঁধেবেঁধেথাকি/Loadable";
import Class10আফ্রিকাPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/আফ্রিকা/Loadable";
import Class10বহুরূপীPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/বহুরূপী/Loadable";
import Class10হারিয়েযাওয়াকালিকলমPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/হারিয়েযাওয়াকালিকলম/Loadable";
import Class10সিরাজদৌল্লাPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/সিরাজদৌল্লা/Loadable";
import Class10অভিষেকPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/অভিষেক/Loadable";
import Class10প্রলয়োল্লাসPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/প্রলয়োল্লাস/Loadable";
import Class10পথেরদাবীPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/পথেরদাবী/Loadable";
import Class10সিন্ধুতীরেPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/সিন্ধুতীরে/Loadable";
import Class10বাংলাভাষায়বিজ্ঞানPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/বাংলাভাষায়বিজ্ঞান/Loadable";
import Class10অদলবদলPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/অদলবদল/Loadable";
import Class10অস্ত্রেরবিরুদ্ধেগানPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/অস্ত্রেরবিরুদ্ধেগান/Loadable";
import Class10নদীরবিদ্রোহPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/নদীরবিদ্রোহ/Loadable";
import Class10কোনিPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/কোনি/Loadable";
import Class10বাক্যপরিবর্তনPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/বাক্যপরিবর্তন/Loadable";
import Class10প্রতিবেদনরচনাPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/প্রতিবেদনরচনা/Loadable";
import Class10বাচ্যপরিবর্তনPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/বাচ্যপরিবর্তন/Loadable";
import Class10প্রবন্ধরচনাPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/প্রবন্ধরচনা/Loadable";
import Class10কারকওঅকারকসম্পর্কPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/কারকওঅকারকসম্পর্ক/Loadable";
import Class10সমাসPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/সমাস/Loadable";
// import Class10সংলাপPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/সংলাপ";
import Class10বঙ্গানুবাদPage from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/বঙ্গানুবাদ/Loadable";
import Class10MadhyamikMockTest1Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest1/Loadable";
import Class10MadhyamikMockTest2Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest2/Loadable";
import Class10MadhyamikMockTest3Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest3/Loadable";
import Class10MadhyamikMockTest4Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest4/Loadable";
import Class10MadhyamikMockTest5Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest5/Loadable";
import Class10MadhyamikMockTest6Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest6/Loadable";
import Class10MadhyamikMockTest7Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest7/Loadable";
import Class10MadhyamikMockTest8Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest8/Loadable";
import Class10MadhyamikMockTest9Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest9/Loadable";
import Class10MadhyamikMockTest10Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest10/Loadable";
import Class10PreviousYearQuestionPaper2017Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2017/Loadable";
import Class10PreviousYearQuestionPaper2018Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2018/Loadable";
import Class10PreviousYearQuestionPaper2019Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2019/Loadable";
import Class10PreviousYearQuestionPaper2020Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2020/Loadable";
import Class10PreviousYearQuestionPaper2022Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2022/Loadable";
import Class10PreviousYearQuestionPaper2023Page from "./containers/ClassX/বাংলা-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2023/Loadable";

//Physical Science
import PhysicalSciencePage from "./containers/ClassX/ভৌতবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/Loadable";
import আয়নীয়ওসমযোজীবন্ধনPage from "./containers/ClassX/ভৌতবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/আয়নীয়ওসমযোজীবন্ধন/Loadable";
import আলোPage from "./containers/ClassX/ভৌতবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/আলো/Loadable";
import গ্যাসেরআচরণPage from "./containers/ClassX/ভৌতবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/গ্যাসেরআচরণ/Loadable";
import চলতড়িৎPage from "./containers/ClassX/ভৌতবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/চলতড়িৎ/Loadable";
import তাপেরঘটনাসমূহPage from "./containers/ClassX/ভৌতবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/তাপেরঘটনাসমূহ/Loadable";
import পরমাণুরনিউক্লিয়াসPage from "./containers/ClassX/ভৌতবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/পরমাণুরনিউক্লিয়াস/Loadable";
import পরিবেশেরজন্যভাবনাPage from "./containers/ClassX/ভৌতবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/পরিবেশেরজন্যভাবনা/Loadable";
import পর্যায়সারণিএবংমৌলদেরধর্মেরপর্যাবৃত্ততাPage from "./containers/ClassX/ভৌতবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/পর্যায়সারণিএবংমৌলদেরধর্মেরপর্যাবৃত্ততা/Loadable";
import রাসায়নিকগণনাPage from "./containers/ClassX/ভৌতবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/রাসায়নিকগণনা/Loadable";

//Geography
import ভূগোলমাধ্যমিকপ্রস্তুতিPage from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/Loadable";
import বহির্জাতপ্রক্রিয়াওতাদেরদ্বারাসৃষ্টভূমিরূপPage from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/বহির্জাতপ্রক্রিয়াওতাদেরদ্বারাসৃষ্টভূমিরূপ/Loadable";
import বায়ুমণ্ডলPage from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/বায়ুমণ্ডল/Loadable";
import বারিমণ্ডলPage from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/বারিমণ্ডল/Loadable";
import বর্জ্যব্যবস্থাপনাPage from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/বর্জ্যব্যবস্থাপনা/Loadable";
import ভারতেরপ্রাকৃতিকপরিবেশPage from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/ভারতেরপ্রাকৃতিকপরিবেশ/Loadable";
import ভারতেরঅর্থনৈতিকপরিবেশPage from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/ভারতেরঅর্থনৈতিকপরিবেশ/Loadable";
import উপগ্রহচিত্রওভূবৈচিত্র্যসূচকমানচিত্রPage from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/উপগ্রহচিত্রওভূবৈচিত্র্যসূচকমানচিত্র/Loadable";
import MockTest1Page from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/MockTest1/Loadable";
import MockTest2Page from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/MockTest2/Loadable";
import MockTest3Page from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/MockTest3/Loadable";
import MockTest4Page from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/MockTest4/Loadable";
import PreviousYearQuestionPaper2017Page from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2017/Loadable";
import PreviousYearQuestionPaper2018Page from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2018/Loadable";
import PreviousYearQuestionPaper2019Page from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2019/Loadable";
import PreviousYearQuestionPaper2020Page from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2020/Loadable";
import PreviousYearQuestionPaper2022Page from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2022/Loadable";
import PreviousYearQuestionPaper2023Page from "./containers/ClassX/ভূগোল-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2023/Loadable";

import জীবনবিজ্ঞানমাধ্যমিকপ্রস্তুতিPage from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/Loadable";
import জীবজগতেনিয়ন্ত্রণওসমন্বয়Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/জীবজগতেনিয়ন্ত্রণওসমন্বয়/Loadable";
import জীবনেরপ্রবাহমানতাPage from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/জীবনেরপ্রবাহমানতা/Loadable";
import বংশগতিএবংকয়েকটিসাধারণজিনগতরোগPage from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/বংশগতিএবংকয়েকটিসাধারণজিনগতরোগ/Loadable";
import অভিব্যক্তিওঅভিযোজনPage from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/অভিব্যক্তিওঅভিযোজন/Loadable";
import পরিবেশওতারসম্পদএবংতাদেরসংরক্ষণPage from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/পরিবেশওতারসম্পদএবংতাদেরসংরক্ষণ/Loadable";
import LifeScienceMadhyamikMockTest1Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest1/Loadable";
import LifeScienceMadhyamikMockTest2Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest2/Loadable";
import LifeScienceMadhyamikMockTest3Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest3/Loadable";
import LifeScienceMadhyamikMockTest4Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest4/Loadable";
import LifeScienceMadhyamikMockTest5Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest5/Loadable";
import LifeScienceMadhyamikMockTest6Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest6/Loadable";
import LifeScienceMadhyamikMockTest7Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest7/Loadable";
import LifeScienceMadhyamikMockTest8Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest8/Loadable";
import LifeSciencePreviousYearQuestionPaper2017Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2017/Loadable";
import LifeSciencePreviousYearQuestionPaper2018Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2018/Loadable";
import LifeSciencePreviousYearQuestionPaper2020Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2020/Loadable";
import LifeSciencePreviousYearQuestionPaper2022Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2022/Loadable";
import LifeSciencePreviousYearQuestionPaper2023Page from "./containers/ClassX/জীবনবিজ্ঞান-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2023/Loadable";

import ইতিহাসমাধ্যমিকপ্রস্তুতিPage from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/Loadable";
import ইতিহাসেরধারণাPage from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/ইতিহাসেরধারণা/Loadable";
import সংস্কারবৈশিষ্ট্যওমূল্যায়নPage from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/সংস্কারবৈশিষ্ট্যওমূল্যায়ন/Loadable";
import প্রতিরোধওবিদ্রোহবৈশিষ্ট্যওবিশ্লেষণPage from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/প্রতিরোধওবিদ্রোহবৈশিষ্ট্যওবিশ্লেষণ/Loadable";
import সংঘবদ্ধতারগোড়ারকথাবিশ্লেষণওবৈশিষ্ট্যPage from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/সংঘবদ্ধতারগোড়ারকথাবিশ্লেষণওবৈশিষ্ট্য/Loadable";
import বিকল্পচিন্তাওউদ্যোগবৈশিষ্ট্যওপর্যালোচনাPage from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/বিকল্পচিন্তাওউদ্যোগবৈশিষ্ট্যওপর্যালোচনা/Loadable";
import বিংশশতকেরভারতেকৃষকশ্রমিকওবামপন্থীআন্দোলনবৈশিষ্ট্যওপর্যালোচনাPage from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/বিংশশতকেরভারতেকৃষক,শ্রমিকওবামপন্থীআন্দোলনবৈশিষ্ট্যওপর্যালোচনা/Loadable";
import বিংশশতকেরভারতেরনারীছাত্রওপ্রান্তিকজনগোষ্ঠীরআন্দোলনবৈশিষ্ট্যওবিশ্লেষণPage from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/বিংশশতকেরভারতেরনারী,ছাত্রওপ্রান্তিকজনগোষ্ঠীরআন্দোলনবৈশিষ্ট্যওবিশ্লেষণ/Loadable";
import উত্তরঔপনিবেশিকভারতবিশশতকেরদ্বিতীয়পর্ব১৯৪৭১৯৬৪Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/উত্তরঔপনিবেশিকভারতবিশশতকেরদ্বিতীয়পর্ব(১৯৪৭১৯৬৪)/Loadable";
import HistoryMadhyamikMockTest1Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest1/Loadable";
import HistoryMadhyamikMockTest2Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest2/Loadable";
import HistoryMadhyamikMockTest3Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest3/Loadable";
import HistoryMadhyamikMockTest4Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest4/Loadable";
import HistoryMadhyamikMockTest5Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest5/Loadable";
import HistoryMadhyamikMockTest6Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest6/Loadable";
import HistoryMadhyamikMockTest7Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest7/Loadable";
import HistoryMadhyamikMockTest8Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest8/Loadable";
import HistoryMadhyamikMockTest9Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest9/Loadable";
import HistoryMadhyamikMockTest10Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest10/Loadable";
import HistoryMadhyamikMockTest1150numbersPage from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/MadhyamikMockTest11(50numbers)/Loadable";
import HistoryPreviousYearQuestionPaper2017Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2017/Loadable";
import HistoryPreviousYearQuestionPaper2018Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2018/Loadable";
import HistoryPreviousYearQuestionPaper2019Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2019/Loadable";
import HistoryPreviousYearQuestionPaper2020Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2020/Loadable";
import HistoryPreviousYearQuestionPaper2022Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2022/Loadable";
import HistoryPreviousYearQuestionPaper2023Page from "./containers/ClassX/ইতিহাস-মাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2023/Loadable";

import Class9বাংলাক্লাস৯Page from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/Loadable";
import Class9কলিঙ্গদেশেঝড়বৃষ্টিPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/কলিঙ্গদেশেঝড়বৃষ্টি/Loadable";
import Class9নোঙরPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/নোঙর/Loadable";
import Class9খেয়াPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/খেয়া/Loadable";
import Class9আকাশেসাতটিতারাPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/আকাশেসাতটিতারা/Loadable";
import Class9আবহমানPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/আবহমান/Loadable";
import Class9ভাঙারগানPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/ভাঙারগান/Loadable";
import Class9আমরাPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/আমরা/Loadable";
import Class9ইলিয়াসPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/ইলিয়াস/Loadable";
import Class9দামPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/দাম/Loadable";
import Class9নিরুদ্দেশPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/নিরুদ্দেশ/Loadable";
import Class9রাধারাণীPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/রাধারাণী/Loadable";
import Class9চন্দ্ৰনাথPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/চন্দ্ৰনাথ/Loadable";
import Class9নবনবসৃষ্টিPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/নবনবসৃষ্টি/Loadable";
import Class9হিমালয়দর্শনPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/হিমালয়দর্শন/Loadable";
import Class9চিঠিPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/চিঠি/Loadable";
import Class9ধীবরবৃত্তান্তPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/ধীবরবৃত্তান্ত/Loadable";
import Class9ব্যোমযাত্রীরডায়রিPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/ব্যোমযাত্রীরডায়রি/Loadable";
import Class9কর্ভাসPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/কর্ভাস/Loadable";
import Class9স্বর্ণপণীPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/স্বর্ণপণী/Loadable";
import Class9FirstUnitTestPage from "./containers/ক্লাস৯/বাংলা-ক্লাস৯/FirstUnitTest/Loadable";

import Class9Englishক্লাস৯Page from "./containers/ক্লাস৯/English-ক্লাস৯/Loadable";
import Class9TalesofBholaGrandpaPage from "./containers/ক্লাস৯/English-ক্লাস৯/TalesofBholaGrandpa/Loadable";
import Class9AllaboutaDogPage from "./containers/ক্লাস৯/English-ক্লাস৯/AllaboutaDog/Loadable";
import Class9AutumnPage from "./containers/ক্লাস৯/English-ক্লাস৯/Autumn/Loadable";
import Class9ADayintheZooPage from "./containers/ক্লাস৯/English-ক্লাস৯/ADayintheZoo/Loadable";
import Class9AllSummerinaDayPage from "./containers/ক্লাস৯/English-ক্লাস৯/AllSummerinaDay/Loadable";
import Class9MildtheMistupontheHillPage from "./containers/ক্লাস৯/English-ক্লাস৯/MildtheMistupontheHill/Loadable";
import Class9TomLosesaToothPage from "./containers/ক্লাস৯/English-ক্লাস৯/TomLosesaTooth/Loadable";
import Class9HisFirstFlightPage from "./containers/ক্লাস৯/English-ক্লাস৯/HisFirstFlight/Loadable";
import Class9TheNorthShipPage from "./containers/ক্লাস৯/English-ক্লাস৯/TheNorthShip/Loadable";
import Class9ThePriceofBananasPage from "./containers/ক্লাস৯/English-ক্লাস৯/ThePriceofBananas/Loadable";
import Class9AShipwreckedSailorPage from "./containers/ক্লাস৯/English-ক্লাস৯/AShipwreckedSailor/Loadable";
import Class9HuntingSnakePage from "./containers/ক্লাস৯/English-ক্লাস৯/HuntingSnake/Loadable";
import Class9TextallwritingskillsPage from "./containers/ক্লাস৯/English-ক্লাস৯/Textallwritingskills/Loadable";

import Class9ইতিহাসক্লাস৯Page from "./containers/ক্লাস৯/ইতিহাস-ক্লাস৯/Loadable";
import Class9ফরাসিবিপ্লবেরকয়েকটিদিকPage from "./containers/ক্লাস৯/ইতিহাস-ক্লাস৯/ফরাসিবিপ্লবেরকয়েকটিদিক/Loadable";
import Class9বিপ্লবীআদৰ্শনেপোলিয়নীয়সাম্রাজ্যওজাতীয়তাবাদPage from "./containers/ক্লাস৯/ইতিহাস-ক্লাস৯/বিপ্লবীআদৰ্শনেপোলিয়নীয়সাম্রাজ্যওজাতীয়তাবাদ/Loadable";
import Class9ঊনবিংশশতকেরইউরোপরাজতান্ত্রিকওজাতীয়তাবাদীভাবধারারসংঘাতPage from "./containers/ক্লাস৯/ইতিহাস-ক্লাস৯/ঊনবিংশশতকেরইউরোপরাজতান্ত্রিকওজাতীয়তাবাদীভাবধারারসংঘাত/Loadable";
import Class9শিল্পবিপ্লবউপনিবেশবাদওসাম্রাজ্যবাদPage from "./containers/ক্লাস৯/ইতিহাস-ক্লাস৯/শিল্পবিপ্লবউপনিবেশবাদওসাম্রাজ্যবাদ/Loadable";
import Class9বিশশতকেইউরোপPage from "./containers/ক্লাস৯/ইতিহাস-ক্লাস৯/বিশশতকেইউরোপ/Loadable";
import Class9দ্বিতীয়বিশ্বযুদ্ধওতারপরPage from "./containers/ক্লাস৯/ইতিহাস-ক্লাস৯/দ্বিতীয়বিশ্বযুদ্ধওতারপর/Loadable";
import Class9জাতিসংঘএবংসম্মিলিতজাতিপুঞ্জPage from "./containers/ক্লাস৯/ইতিহাস-ক্লাস৯/জাতিসংঘএবংসম্মিলিতজাতিপুঞ্জ/Loadable";
import Class9HistoryFirstUnitTestPage from "./containers/ক্লাস৯/ইতিহাস-ক্লাস৯/FirstUnitTest/Loadable";

import Class9ভূগোলক্লাস৯Page from "./containers/ক্লাস৯/ভূগোল-ক্লাস৯/Loadable";
import Class9গ্রহরূপেপৃথিবীPage from "./containers/ক্লাস৯/ভূগোল-ক্লাস৯/গ্রহরূপেপৃথিবী/Loadable";
import Class9পৃথিবীরগতিসমূহPage from "./containers/ক্লাস৯/ভূগোল-ক্লাস৯/পৃথিবীরগতিসমূহ/Loadable";
import Class9পৃথিবীপৃষ্ঠেকোনোস্থানেরঅবস্থাননির্ণয়Page from "./containers/ক্লাস৯/ভূগোল-ক্লাস৯/পৃথিবীপৃষ্ঠেকোনোস্থানেরঅবস্থাননির্ণয়/Loadable";
import Class9ভূগাঠনিকপ্রক্রিয়াএবংপৃথিবীরবিভিন্নভূমিরূপPage from "./containers/ক্লাস৯/ভূগোল-ক্লাস৯/ভূগাঠনিকপ্রক্রিয়াএবংপৃথিবীরবিভিন্নভূমিরূপ/Loadable";
import Class9আবহবিকারPage from "./containers/ক্লাস৯/ভূগোল-ক্লাস৯/আবহবিকার/Loadable";
import Class9দুর্যোগওবিপর্যয়Page from "./containers/ক্লাস৯/ভূগোল-ক্লাস৯/দুর্যোগওবিপর্যয়/Loadable";
import Class9ভারতেরসম্পদPage from "./containers/ক্লাস৯/ভূগোল-ক্লাস৯/ভারতেরসম্পদ/Loadable";
import Class9পশ্চিমবঙ্গPage from "./containers/ক্লাস৯/ভূগোল-ক্লাস৯/পশ্চিমবঙ্গ/Loadable";
import Class9GeographyFirstUnitTestPage from "./containers/ক্লাস৯/ভূগোল-ক্লাস৯/FirstUnitTest/Loadable";

import Class9জীবনবিজ্ঞাননবমশ্রেণিPage from "./containers/ক্লাস৯/জীবনবিজ্ঞান-নবমশ্রেণি/Loadable";
import Class9জীবনওতারবৈচিত্র্যPage from "./containers/ক্লাস৯/জীবনবিজ্ঞান-নবমশ্রেণি/জীবনওতারবৈচিত্র্য/Loadable";
import Class9জীবনসংগঠনেরস্তরPage from "./containers/ক্লাস৯/জীবনবিজ্ঞান-নবমশ্রেণি/জীবনসংগঠনেরস্তর/Loadable";
import Class9জৈবনিকপ্রক্রিয়াPage from "./containers/ক্লাস৯/জীবনবিজ্ঞান-নবমশ্রেণি/জৈবনিকপ্রক্রিয়া/Loadable";
import Class9জীববিদ্যাওমানকল্যাণPage from "./containers/ক্লাস৯/জীবনবিজ্ঞান-নবমশ্রেণি/জীববিদ্যাওমানকল্যাণ/Loadable";
import Class9পরিবেশওতারসম্পদPage from "./containers/ক্লাস৯/জীবনবিজ্ঞান-নবমশ্রেণি/পরিবেশওতারসম্পদ/Loadable";
import Class9LifeScienceFirstUnitTestPage from "./containers/ক্লাস৯/জীবনবিজ্ঞান-নবমশ্রেণি/FirstUnitTest/Loadable";

import Class9ভৌতবিজ্ঞানক্লাস৯Page from "./containers/ক্লাস৯/ভৌতবিজ্ঞান-ক্লাস৯/Loadable";
import Class9পরিমাপPage from "./containers/ক্লাস৯/ভৌতবিজ্ঞান-ক্লাস৯/পরিমাপ/Loadable";
import Class9বলওগতিPage from "./containers/ক্লাস৯/ভৌতবিজ্ঞান-ক্লাস৯/বলওগতি/Loadable";
import Class9পদার্থগঠনওধর্মPage from "./containers/ক্লাস৯/ভৌতবিজ্ঞান-ক্লাস৯/পদার্থগঠনওধর্ম/Loadable";
import Class9পদার্থপরমাণুরগঠনওপদার্থেরভৌতওরাসায়নিকধর্মসমূহPage from "./containers/ক্লাস৯/ভৌতবিজ্ঞান-ক্লাস৯/পদার্থপরমাণুরগঠনওপদার্থেরভৌতওরাসায়নিকধর্মসমূহ/Loadable";
import Class9শক্তিরক্রিয়াকার্যক্ষমতাওশক্তিPage from "./containers/ক্লাস৯/ভৌতবিজ্ঞান-ক্লাস৯/শক্তিরক্রিয়াকার্যক্ষমতাওশক্তি/Loadable";
import Class9তাপPage from "./containers/ক্লাস৯/ভৌতবিজ্ঞান-ক্লাস৯/তাপ/Loadable";
import Class9শব্দPage from "./containers/ক্লাস৯/ভৌতবিজ্ঞান-ক্লাস৯/শব্দ/Loadable";

import Class8Page from "./containers/ক্লাস৮/Loadable";
import Class8বাংলাক্লাস৮Page from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/Loadable";
import Class8বোঝাপড়াPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/বোঝাপড়া/Loadable";
import Class8অদ্ভুতআতিথেয়তাPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/অদ্ভুতআতিথেয়তা/Loadable";
import Class8চন্দ্রগুপ্তPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/চন্দ্রগুপ্ত/Loadable";
import Class8বনভোজনেরব্যাপারPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/বনভোজনেরব্যাপার/Loadable";
import Class8নিখিলবঙ্গকবিতাসংঘPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/নিখিলবঙ্গকবিতাসংঘ/Loadable";
import Class8সবুজজামাPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/সবুজজামা/Loadable";
import Class8চিঠিPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/চিঠি/Loadable";
import Class8আলাপPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/আলাপ/Loadable";
import Class8পরবাসীPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/পরবাসী/Loadable";
import Class8পথচলতিPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/পথচলতি/Loadable";
import Class8একটিচড়ুইপাখিPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/একটিচড়ুইপাখি/Loadable";
import Class8দাঁড়াওPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/দাঁড়াও/Loadable";
import Class8পল্লীসমাজPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/পল্লীসমাজ/Loadable";
import Class8ছন্নছাড়াPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/ছন্নছাড়া/Loadable";
import Class8গাছেরকথাPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/গাছেরকথা/Loadable";
import Class8হওয়ারগানPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/হওয়ারগান/Loadable";
import Class8কীকরেবুঝবPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/কীকরেবুঝব/Loadable";
import Class8পাড়াগাঁরদুপহরভালোবাসিPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/পাড়াগাঁরদুপহরভালোবাসি/Loadable";
import Class8নাটোরেরকথাPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/নাটোরেরকথা/Loadable";
// import Class8স্বদেশিকতাPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/স্বদেশিকতা";
import Class8গড়াইনদীরতীরেPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/গড়াইনদীরতীরে/Loadable";
import Class8জেলখানারচিঠিPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/জেলখানারচিঠি/Loadable";
import Class8স্বাধীনতাPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/স্বাধীনতা/Loadable";
import Class8আদাবPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/আদাব/Loadable";
import Class8শিকলপরারগানPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/শিকলপরারগান/Loadable";
import Class8হরিচরণবন্দ্যোপাধ্যায়Page from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/হরিচরণবন্দ্যোপাধ্যায়/Loadable";
// import Class8ভালোবাসাকিবৃথাযায়Page from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/ভালোবাসাকিবৃথাযায়";
import Class8ঘুরেদাঁড়াওPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/ঘুরেদাঁড়াও/Loadable";
import Class8সুভাPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/সুভা/Loadable";
import Class8পরাজয়Page from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/পরাজয়/Loadable";
import Class8মাসিপিসিPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/মাসিপিসি/Loadable";
import Class8টিকিটেরঅ্যালবামPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/টিকিটেরঅ্যালবাম/Loadable";
import Class8লোকটাজানলইনাPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/লোকটাজানলইনা/Loadable";
import Class8BengaliFirstUnitTestPage from "./containers/ক্লাস৮/বাংলা-ক্লাস৮/FirstUnitTest/Loadable";

import Class8ভৌতবিজ্ঞানক্লাস৮Page from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/Loadable";
import Class8বলওচাপPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/বলওচাপ/Loadable";
import Class8স্পর্শছাড়াক্রিয়াশীলবলPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/স্পর্শছাড়াক্রিয়াশীলবল/Loadable";
import Class8তাপPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/তাপ/Loadable";
import Class8আলোPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/আলো/Loadable";
import Class8মৌলযৌগওরাসায়নিকবিক্রিয়াপদার্থেরপ্রকৃতিPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/মৌলযৌগওরাসায়নিকবিক্রিয়াপদার্থেরপ্রকৃতি/Loadable";
import Class8মৌলযৌগওরাসায়নিকবিক্রিয়াপদার্থেরগঠনPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/মৌলযৌগওরাসায়নিকবিক্রিয়াপদার্থেরগঠন/Loadable";
import Class8মৌলযৌগওরাসায়নিকবিক্রিয়ারাসায়নিকবিক্রিয়াPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/মৌলযৌগওরাসায়নিকবিক্রিয়ারাসায়নিকবিক্রিয়া/Loadable";
import Class8মৌলযৌগওরাসায়নিকবিক্রিয়াতড়িতেররাসায়নিকপ্রভাবPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/মৌলযৌগওরাসায়নিকবিক্রিয়াতড়িতেররাসায়নিকপ্রভাব/Loadable";
import Class8কয়েকটিগ্যাসেরপরিচিতিPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/কয়েকটিগ্যাসেরপরিচিতি/Loadable";
import Class8কার্বনওকার্বনঘটিতযৌগPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/কার্বনওকার্বনঘটিতযৌগ/Loadable";
import Class8প্রাকৃতিকঘটনাওতারবিশ্লেষণPage from "./containers/ক্লাস৮/ভৌতবিজ্ঞান-ক্লাস৮/প্রাকৃতিকঘটনাওতারবিশ্লেষণ/Loadable";

import Class8ভূগোলক্লাস৮Page from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/Loadable";
import Class8পৃথিবীরঅন্দরমহলPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/পৃথিবীরঅন্দরমহল/Loadable";
import Class8অস্থিতপৃথিবীPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/অস্থিতপৃথিবী/Loadable";
import Class8শিলাPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/শিলা/Loadable";
import Class8চাপবলয়ওবায়ুপ্রবাহPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/চাপবলয়ওবায়ুপ্রবাহ/Loadable";
import Class8মেঘবৃষ্টিPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/মেঘবৃষ্টি/Loadable";
import Class8জলবায়ুঅঞ্চলPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/জলবায়ুঅঞ্চল/Loadable";
import Class8মানুষেরকার্যাবলিওপরিবেশেরঅবনমনPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/মানুষেরকার্যাবলিওপরিবেশেরঅবনমন/Loadable";
import Class8ভারতেরপ্রতিবেশীদেশসমূহওসঙ্গেসম্পর্কPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/ভারতেরপ্রতিবেশীদেশসমূহওসঙ্গেসম্পর্ক/Loadable";
import Class8উত্তরআমেরিকাPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/উত্তরআমেরিকা/Loadable";
import Class8দক্ষিণআমেরিকাPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/দক্ষিণআমেরিকা/Loadable";
import Class8ওশিয়ানিয়াPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/ওশিয়ানিয়া/Loadable";
import Class8GeographyFirstUnitTestPage from "./containers/ক্লাস৮/ভূগোল-ক্লাস৮/FirstUnitTest/Loadable";

import Class8Englishক্লাস৮Page from "./containers/ক্লাস৮/English-ক্লাস৮/Loadable";
import Class8RevisionlessonPage from "./containers/ক্লাস৮/English-ক্লাস৮/Revisionlesson/Loadable";
import Class8TheWindCapPage from "./containers/ক্লাস৮/English-ক্লাস৮/TheWindCap/Loadable";
import Class8CloudsPage from "./containers/ক্লাস৮/English-ক্লাস৮/Clouds/Loadable";
import Class8ANAPRILDAYPage from "./containers/ক্লাস৮/English-ক্লাস৮/ANAPRILDAY/Loadable";
import Class8TheGreatEscapePage from "./containers/ক্লাস৮/English-ক্লাস৮/TheGreatEscape/Loadable";
import Class8PrincessSeptemberPage from "./containers/ক্লাস৮/English-ক্লাস৮/PrincessSeptember/Loadable";
import Class8TheSeaPage from "./containers/ক্লাস৮/English-ক্লাস৮/TheSea/Loadable";
import Class8AKingsTalePage from "./containers/ক্লাস৮/English-ক্লাস৮/AKingsTale/Loadable";
import Class8TheHappyPrincePage from "./containers/ক্লাস৮/English-ক্লাস৮/TheHappyPrince/Loadable";
import Class8SummerFriendsPage from "./containers/ক্লাস৮/English-ক্লাস৮/SummerFriends/Loadable";
import Class8TalesofChildhoodPage from "./containers/ক্লাস৮/English-ক্লাস৮/TalesofChildhood/Loadable";
import Class8MidnightExpressPage from "./containers/ক্লাস৮/English-ক্লাস৮/MidnightExpress/Loadable";
import Class8SomeonePage from "./containers/ক্লাস৮/English-ক্লাস৮/Someone/Loadable";
import Class8TheManWhoPlantedTreesPage from "./containers/ক্লাস৮/English-ক্লাস৮/TheManWhoPlantedTrees/Loadable";
// import Class81stunittestexam1Page from "./containers/ক্লাস৮/English-ক্লাস৮/1stunittestexam1";
import Class8TextbookallwritingskillPage from "./containers/ক্লাস৮/English-ক্লাস৮/Textbookallwritingskill/Loadable";

import Class8জীবনবিজ্ঞানক্লাস৮Page from "./containers/ক্লাস৮/জীবনবিজ্ঞান-ক্লাস৮/Loadable";
import Class8জীবদেহেরগঠনPage from "./containers/ক্লাস৮/জীবনবিজ্ঞান-ক্লাস৮/জীবদেহেরগঠন/Loadable";
import Class8অণুজীবেরজগৎPage from "./containers/ক্লাস৮/জীবনবিজ্ঞান-ক্লাস৮/অণুজীবেরজগৎ/Loadable";
import Class8মানুষেরখাদ্যওখাদ্যউপাদানPage from "./containers/ক্লাস৮/জীবনবিজ্ঞান-ক্লাস৮/মানুষেরখাদ্যওখাদ্যউপাদান/Loadable";
import Class8অন্তঃক্ষরাতন্ত্রওবয়ঃসন্ধিPage from "./containers/ক্লাস৮/জীবনবিজ্ঞান-ক্লাস৮/অন্তঃক্ষরাতন্ত্রওবয়ঃসন্ধি/Loadable";
import Class8জীববৈচিত্র্যপরিবেশেরসংকটওবিপন্নপ্রাণীসংরক্ষণPage from "./containers/ক্লাস৮/জীবনবিজ্ঞান-ক্লাস৮/জীববৈচিত্র্যপরিবেশেরসংকটওবিপন্নপ্রাণীসংরক্ষণ/Loadable";
import Class8আমাদেরচারপাশেরপরিবেশওউদ্ভিদজগৎPage from "./containers/ক্লাস৮/জীবনবিজ্ঞান-ক্লাস৮/আমাদেরচারপাশেরপরিবেশওউদ্ভিদজগৎ/Loadable";
import Class8LifeScienceFirstUnitTestPage from "./containers/ক্লাস৮/জীবনবিজ্ঞান-ক্লাস৮/FirstUnitTest/Loadable";

import Class8বাংলাব্যাকরণক্লাস৮Page from "./containers/ক্লাস৮/বাংলাব্যাকরণ-ক্লাস৮/Loadable";
import Class8দলPage from "./containers/ক্লাস৮/বাংলাব্যাকরণ-ক্লাস৮/দল/Loadable";
import Class8ধ্বনিপরিবর্তনেরকারণওধারাPage from "./containers/ক্লাস৮/বাংলাব্যাকরণ-ক্লাস৮/ধ্বনিপরিবর্তনেরকারণওধারা/Loadable";
import Class8বাক্যেরভাবওরূপান্তরPage from "./containers/ক্লাস৮/বাংলাব্যাকরণ-ক্লাস৮/বাক্যেরভাবওরূপান্তর/Loadable";
import Class8বিশেষ্যবিশেষণসর্বনামঅব্যয়ওক্রিয়াPage from "./containers/ক্লাস৮/বাংলাব্যাকরণ-ক্লাস৮/বিশেষ্যবিশেষণসর্বনামঅব্যয়ওক্রিয়া/Loadable";
import Class8ক্রিয়ারকালওক্রিয়ারভাবPage from "./containers/ক্লাস৮/বাংলাব্যাকরণ-ক্লাস৮/ক্রিয়ারকালওক্রিয়ারভাব/Loadable";
// import Class8প্রবন্ধরচনাPage from "./containers/ক্লাস৮/বাংলাব্যাকরণ-ক্লাস৮/প্রবন্ধরচনা";
import Class8সমাসPage from "./containers/ক্লাস৮/বাংলাব্যাকরণ-ক্লাস৮/সমাস/Loadable";

import Class8ইতিহাসক্লাস৮Page from "./containers/ক্লাস৮/ইতিহাস-ক্লাস৮/Loadable";
import Class8আঞ্চলিকশক্তিরউত্থানPage from "./containers/ক্লাস৮/ইতিহাস-ক্লাস৮/আঞ্চলিকশক্তিরউত্থান/Loadable";
import Class8ঔপনিবেশিককর্তৃত্বপ্রতিষ্ঠাPage from "./containers/ক্লাস৮/ইতিহাস-ক্লাস৮/ঔপনিবেশিককর্তৃত্বপ্রতিষ্ঠা/Loadable";
import Class8ঔপনিবেশিকঅর্থনীতিরচরিত্রPage from "./containers/ক্লাস৮/ইতিহাস-ক্লাস৮/ঔপনিবেশিকঅর্থনীতিরচরিত্র/Loadable";
import Class8ঔপনিবেশিকশাসনেরপ্রতিক্রিয়াসহযোগিতাওবিদ্রোহPage from "./containers/ক্লাস৮/ইতিহাস-ক্লাস৮/ঔপনিবেশিকশাসনেরপ্রতিক্রিয়াসহযোগিতাওবিদ্রোহ/Loadable";
import Class8জাতীয়তাবাদেরপ্রাথমিকবিকাশPage from "./containers/ক্লাস৮/ইতিহাস-ক্লাস৮/জাতীয়তাবাদেরপ্রাথমিকবিকাশ/Loadable";
import Class8ভারতেরজাতীয়আন্দোলনেরআদর্শওবিবর্তনPage from "./containers/ক্লাস৮/ইতিহাস-ক্লাস৮/ভারতেরজাতীয়আন্দোলনেরআদর্শওবিবর্তন/Loadable";
import Class8সাম্প্রদায়িকতাথেকেদেশভাগPage from "./containers/ক্লাস৮/ইতিহাস-ক্লাস৮/সাম্প্রদায়িকতাথেকেদেশভাগ/Loadable";
import Class8ভারতীয়সংবিধানগণতন্ত্রেরকাঠামোওজনগণেরঅধিকারPage from "./containers/ক্লাস৮/ইতিহাস-ক্লাস৮/ভারতীয়সংবিধানগণতন্ত্রেরকাঠামোওজনগণেরঅধিকার/Loadable";
import Class8HistoryFirstUnitTestPage from "./containers/ক্লাস৮/ইতিহাস-ক্লাস৮/FirstUnitTest/Loadable";

import Class12Page from "./containers/Class12/Loadable";

import Class12বাংলাউচ্চমাধ্যমিকপ্রস্তুতিPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/Loadable";
import Class12ভাতগল্পPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/ভাতগল্প/Loadable";
import Class12কেবাঁচায়কেবাঁচেPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/কেবাঁচায়কেবাঁচে/Loadable";
import Class12রূপনারায়ণেরকূলেPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/রূপনারায়ণেরকূলে/Loadable";
import Class12শিকারPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/শিকার/Loadable";
import Class12আমিদেখিPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/আমিদেখি/Loadable";
import Class12মহুয়ারদেশPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/মহুয়ারদেশ/Loadable";
import Class12ক্রন্দনরতাজননীরপাশেPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/ক্রন্দনরতাজননীরপাশে/Loadable";
import Class12বিভাবনাটকPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/বিভাবনাটক/Loadable";
import Class12নানারঙেরদিননাটকPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/নানারঙেরদিননাটক/Loadable";
import Class12পড়তেজানেএমনএকমজুরেরপ্রশ্নPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/পড়তেজানেএমনএকমজুরেরপ্রশ্ন/Loadable";
import Class12অলৌকিকPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/অলৌকিক/Loadable";
import Class12আমারবাংলাPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/আমারবাংলা/Loadable";
import Class12ভারতবর্ষPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/ভারতবর্ষ/Loadable";
import Class12ধ্বনিতত্বPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/ধ্বনিতত্ব/Loadable";
import Class12ভাষাবিজ্ঞানওতারশাখাপ্রশাখাPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/ভাষাবিজ্ঞানওতারশাখাপ্রশাখা/Loadable";
import Class12বাক্যতত্ত্বভাষাPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/বাক্যতত্ত্বভাষা/Loadable";
import Class12শব্দার্থতত্ত্বভাষাPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/শব্দার্থতত্ত্বভাষা/Loadable";
import Class12রূপতত্ত্বভাষাPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/রূপতত্ত্বভাষা/Loadable";
import Class12বাংলাগানেরধারাPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/বাংলাগানেরধারা/Loadable";
import Class12বাঙালিরচিত্রকলাPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/বাঙালিরচিত্রকলা/Loadable";
import Class12বাংলাচলচ্চিত্রেরকথাPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/বাংলাচলচ্চিত্রেরকথা/Loadable";
import Class12বাঙালিরবিজ্ঞানচর্চাPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/বাঙালিরবিজ্ঞানচর্চা/Loadable";
import Class12বাঙালিরক্রীরাসংস্কৃতিPage from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/বাঙালিরক্রীরাসংস্কৃতি/Loadable";
// import Class12PreviousYearQuestionPaper2017Page from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2017";
// import Class12PreviousYearQuestionPaper2018Page from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2018";
// import Class12PreviousYearQuestionPaper2019Page from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2019";
// import Class12PreviousYearQuestionPaper2020Page from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2020";
// import Class12PreviousYearQuestionPaper2022Page from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2022";
// import Class12PreviousYearQuestionPaper2023Page from "./containers/Class12/বাংলা-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2023";

import Class12Englishউচ্চমাধ্যমিকপ্রস্তুতিPage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/Loadable";
import Class12TheEyesHaveItPage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/TheEyesHaveIt/Loadable";
import Class12StrongRootsPage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/StrongRoots/Loadable";
import Class12ThankyoumaamPage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/Thankyoumaam/Loadable";
import Class12ThreeQuestionsPage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/ThreeQuestions/Loadable";
import Class12OnKillingATreePage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/OnKillingATree/Loadable";
import Class12AsleepInTheValleyPage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/AsleepInTheValley/Loadable";
import Class12ShallICompareTheePage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/ShallICompareThee/Loadable";
import Class12ThePoetryofEarthPage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/ThePoetryofEarth/Loadable";
import Class12TheProposalPage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/TheProposal/Loadable";
import Class12WritingSkillsPage from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/WritingSkills/Loadable";
import Class12PreviousYearQuestionPaper2017Page from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2017/Loadable";
import Class12PreviousYearQuestionPaper2018Page from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2018/Loadable";
import Class12PreviousYearQuestionPaper2019Page from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2019/Loadable";
import Class12PreviousYearQuestionPaper2020Page from "./containers/Class12/English-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousYearQuestionPaper2020/Loadable";

import Class12সংস্কৃতউচ্চমাধ্যমিকপ্রস্তুতিPage from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/Loadable";
import Class12বনগতাগুহাPage from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/বনগতাগুহা/Loadable";
import Class12শ্রীমদ্ভাগবদ্গীতাকর্মযোগPage from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/শ্রীমদ্ভাগবদ্গীতাকর্মযোগ/Loadable";
import Class12আর্যাবর্তবর্ণনম্Page from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/আর্যাবর্তবর্ণনম্/Loadable";
import Class12শ্রীগঙ্গাস্তোত্রম্Page from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/শ্রীগঙ্গাস্তোত্রম্/Loadable";
import Class12বাসন্তিকস্বপ্নম্Page from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/বাসন্তিকস্বপ্নম্/Loadable";
import Class12সংস্কৃতসাহিত্যেরইতিহাসPage from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/সংস্কৃতসাহিত্যেরইতিহাস/Loadable";
import Class12ভাবসম্প্রসারণPage from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/ভাবসম্প্রসারণ/Loadable";
import Class12সমাসPage from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/সমাস/Loadable";
import Class12শব্দযুগলেরঅর্থপার্থক্যPage from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/শব্দযুগলেরঅর্থপার্থক্য/Loadable";
import Class12প্রত্যয়Page from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/প্রত্যয়/Loadable";
import Class12বাংলাথেকেসংস্কৃতেঅনুবাদPage from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/বাংলাথেকেসংস্কৃতেঅনুবাদ/Loadable";
import Class12অনুচ্ছেদরচনাPage from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/অনুচ্ছেদরচনা/Loadable";
import Class12SanskritPreviousyearQuestionpaper2017Page from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousyearQuestionpaper2017/Loadable";
import Class12SanskritPreviousyearQuestionpaper2018Page from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousyearQuestionpaper2018/Loadable";
import Class12SanskritPreviousyearQuestionpaper2019Page from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousyearQuestionpaper2019/Loadable";
import Class12SanskritPreviousyearQuestionpaper2022Page from "./containers/Class12/সংস্কৃত-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousyearQuestionpaper2022/Loadable";

import Class12ভূগোলউচ্চমাধ্যমিকপ্রস্তুতিPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি";
import Class12ভূমিরূপগঠনকারীপ্রক্রিয়াসমূহবহিজাতপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/ভূমিরূপগঠনকারীপ্রক্রিয়াসমূহবহিজাতপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপ";
import Class12ভৌমজলেরকার্যএবংসংশ্লিষ্টভূমিরূপPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/ভৌমজলেরকার্যএবংসংশ্লিষ্টভূমিরূপ";
import Class12সামুদ্রিকপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/সামুদ্রিকপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপ";
import Class12ক্ষয়চক্রগঠনওপ্রক্রিয়াPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/ক্ষয়চক্রগঠনওপ্রক্রিয়া";
import Class12জলনির্গমপ্রণালীPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/জলনির্গমপ্রণালী";
import Class12মৃত্তিকাPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/মৃত্তিকা";
import Class12বায়ুমণ্ডলPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/বায়ুমণ্ডল";
import Class12জলবায়ুপরিবর্তনPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/জলবায়ুপরিবর্তন";
import Class12মানুষপরিবেশআন্তঃসম্পর্কপ্রাকৃতিকদুর্যোগওবিপর্যয়Page from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/মানুষপরিবেশআন্তঃসম্পর্কপ্রাকৃতিকদুর্যোগওবিপর্যয়";
import Class12অর্থনৈতিকক্রিয়াকলাপPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/অর্থনৈতিকক্রিয়াকলাপ";
import Class12প্রাথমিকক্রিয়াকলাপকৃষিকাজPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/প্রাথমিকক্রিয়াকলাপকৃষিকাজ";
import Class12দ্বিতীয়স্তরেরক্রিয়াকলাপশিল্পPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/দ্বিতীয়স্তরেরক্রিয়াকলাপশিল্প";
import Class12তৃতীয়স্তরেরক্রিয়াকলাপPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/তৃতীয়স্তরেরক্রিয়াকলাপ";
import Class12চতুর্থস্তরেরক্রিয়াকলাপPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/চতুর্থস্তরেরক্রিয়াকলাপ";
import Class12পঞ্চমস্তরেরক্রিয়াকলাপPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/পঞ্চমস্তরেরক্রিয়াকলাপ";
import Class12জনসংখ্যাওজনবসতিPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/জনসংখ্যাওজনবসতি";
import Class12আঞ্চলিকঅর্থনৈতিকউন্নয়নPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/আঞ্চলিকঅর্থনৈতিকউন্নয়ন";
import Class12জীববৈচিত্র্যPage from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/জীববৈচিত্র্য";
import Class12GeographyPreviousyearQuestionpaper2017Page from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousyearQuestionpaper2017";
import Class12GeographyPreviousyearQuestionpaper2018Page from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousyearQuestionpaper2018";
import Class12GeographyPreviousyearQuestionpaper2019Page from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousyearQuestionpaper2019";
import Class12GeographyPreviousyearQuestionpaper2022Page from "./containers/Class12/ভূগোল-উচ্চমাধ্যমিকপ্রস্তুতি/PreviousyearQuestionpaper2022";

import Class12জীববিদ্যাউচ্চমাধ্যমিকপ্রস্তুতিPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি";
import Class12জীবেরজননPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/জীবেরজনন";
import Class12সপুষ্পকউদ্ভিদেরযৌনজননPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/সপুষ্পকউদ্ভিদেরযৌনজনন";
import Class12মানুষেরজননPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/মানুষেরজনন";
import Class12জননগতস্বাস্থ্যPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/জননগতস্বাস্থ্য";
import Class12বংশগতিওবিভেদPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/বংশগতিওবিভেদ";
import Class12বংশগতিরআণবিকভিত্তিPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/বংশগতিরআণবিকভিত্তি";
import Class12অভিব্যক্তিবাবিবর্তনPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/অভিব্যক্তিবাবিবর্তন";
import Class12অভিব্যক্তিরকৌশলPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/অভিব্যক্তিরকৌশল";
import Class12স্বাস্থ্যওরোগPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/স্বাস্থ্যওরোগ";
import Class12খাদ্যউৎপাদনেরউন্নতিসাধনPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/খাদ্যউৎপাদনেরউন্নতিসাধন";
import Class12মানবকল্যাণেঅণুজীবPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/মানবকল্যাণেঅণুজীব";
import Class12জীবপ্রযুক্তিএবংএরপ্রয়োগPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/জীবপ্রযুক্তিএবংএরপ্রয়োগ";
import Class12বাস্তুবিদ্যাপরিবেশওপপুলেশনPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/বাস্তুবিদ্যাপরিবেশওপপুলেশন";
import Class12বাস্তুতন্ত্রPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/বাস্তুতন্ত্র";
import Class12জীববৈচিত্র্যওসংরক্ষণPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/জীববৈচিত্র্যওসংরক্ষণ";
import Class12পরিবেশসংক্রান্তবিষয়সমূহPage from "./containers/Class12/জীববিদ্যা-উচ্চমাধ্যমিকপ্রস্তুতি/পরিবেশসংক্রান্তবিষয়সমূহ";

import Class12দর্শনউচ্চমাধ্যমিকপ্রস্তুতিPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি";
import Class12যুক্তিPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/যুক্তি";
import Class12বচনPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/বচন";
import Class12বচনেরবিরোধিতাPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/বচনেরবিরোধিতা";
import Class12অমাধ্যমঅনুমানPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/অমাধ্যমঅনুমান";
import Class12নিরপেক্ষন্যায়Page from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/নিরপেক্ষন্যায়";
import Class12প্রাকল্পিকওবৈকল্পিকন্যায়Page from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/প্রাকল্পিকওবৈকল্পিকন্যায়";
import Class12বুলীয়ভাষ্যওভেনচিত্রPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/বুলীয়ভাষ্যওভেনচিত্র";
import Class12সত্যাপেক্ষকPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/সত্যাপেক্ষক";
import Class12আরোহঅনুমানেরস্বরূপPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/আরোহঅনুমানেরস্বরূপ";
import Class12কারণPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/কারণ";
import Class12মিলেরপরীক্ষামূলকপদ্ধতিPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/মিলেরপরীক্ষামূলকপদ্ধতি";
import Class12আরোহমূলকদোষPage from "./containers/Class12/দর্শন-উচ্চমাধ্যমিকপ্রস্তুতি/আরোহমূলকদোষ";

import Class12রাষ্ট্রবিজ্ঞানউচ্চমাধ্যমিকপ্রস্তুতিPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি";
import Class12আন্তর্জাতিকসম্পর্কPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/আন্তর্জাতিকসম্পর্ক";
import Class12দ্বিতীয়বিশ্বযুদ্ধেরপরবর্তীপর্বেরআন্তর্জাতিকসম্পর্কPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/দ্বিতীয়বিশ্বযুদ্ধেরপরবর্তীপর্বেরআন্তর্জাতিকসম্পর্ক";
import Class12বিদেশনীতিবাপররাষ্ট্রনীতিPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/বিদেশনীতিবাপররাষ্ট্রনীতি";
import Class12সম্মিলিতজাতিপুঞ্জPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/সম্মিলিতজাতিপুঞ্জ";
import Class12কয়েকটিপ্রধানরাজনৈতিকমতবাদPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/কয়েকটিপ্রধানরাজনৈতিকমতবাদ";
import Class12সরকারেরবিভিন্নবিভাগPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/সরকারেরবিভিন্নবিভাগ";
import Class12ভারতেরশাসনবিভাগPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/ভারতেরশাসনবিভাগ";
import Class12ভারতেরআইনবিভাগPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/ভারতেরআইনবিভাগ";
import Class12ভারতেরবিচারবিভাগPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/ভারতেরবিচারবিভাগ";
import Class12স্থানীয়স্বায়ত্বশাসনব্যবস্থাPage from "./containers/Class12/রাষ্ট্রবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/স্থানীয়স্বায়ত্বশাসনব্যবস্থা";

import Class12শিক্ষাবিজ্ঞানউচ্চমাধ্যমিকপ্রস্তুতিPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি";
// import Class12শিখনPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/শিখন";
import Class12শিখনেরকৌশলPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/শিখনেরকৌশল";
import Class12শিক্ষায়রাশিবিজ্ঞানPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/শিক্ষায়রাশিবিজ্ঞান";
// import Class12ভারতীয়সংবিধানেউল্লিখিতশিক্ষাসম্পর্কেবিভিন্নধারাPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/ভারতীয়সংবিধানেউল্লিখিতশিক্ষাসম্পর্কেবিভিন্নধারা";
// import Class12বিশ্ববিদ্যালয়শিক্ষাকমিশন194849Page from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/বিশ্ববিদ্যালয়শিক্ষাকমিশন(194849)";
// import Class12মাধ্যমিকশিক্ষাকমিশন195253Page from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/মাধ্যমিকশিক্ষাকমিশন(195253)";
// import Class12ভারতীয়শিক্ষাকমিশনPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/ভারতীয়শিক্ষাকমিশন";
import Class12জাতীয়শিক্ষানীতিPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/জাতীয়শিক্ষানীতি";
import Class12ব্যতিক্রমধর্মীশিশুরশিক্ষাPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/ব্যতিক্রমধর্মীশিশুরশিক্ষা";
// import Class12প্রাথমিকশিক্ষারসর্বজনীনতাPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/প্রাথমিকশিক্ষারসর্বজনীনতা";
import Class12শিক্ষারবিশ্বব্যাপীদৃষ্টিভঙ্গিPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/শিক্ষারবিশ্বব্যাপীদৃষ্টিভঙ্গি";
// import Class12শিক্ষাপ্রযুক্তিবিদ্যাPage from "./containers/Class12/শিক্ষাবিজ্ঞান-উচ্চমাধ্যমিকপ্রস্তুতি/শিক্ষাপ্রযুক্তিবিদ্যা";

import Class12ইতিহাসউচ্চমাধ্যমিকপ্রস্তুতিPage from "./containers/Class12/ইতিহাস-উচ্চমাধ্যমিকপ্রস্তুতি";
import Class12অতীতকেস্মরণPage from "./containers/Class12/ইতিহাস-উচ্চমাধ্যমিকপ্রস্তুতি/অতীতকেস্মরণ";
import Class12ঊনবিংশওবিংশশতকেঔপনিবেশিকতাবাদওসাম্রাজ্যবাদেরপ্রসারPage from "./containers/Class12/ইতিহাস-উচ্চমাধ্যমিকপ্রস্তুতি/ঊনবিংশওবিংশশতকেঔপনিবেশিকতাবাদওসাম্রাজ্যবাদেরপ্রসার";
import Class12ঔপনিবেশিকআধিপত্যেরপ্রকৃতিনিয়মিতওঅনিয়মিতসাম্রাজ্যPage from "./containers/Class12/ইতিহাস-উচ্চমাধ্যমিকপ্রস্তুতি/ঔপনিবেশিকআধিপত্যেরপ্রকৃতিনিয়মিতওঅনিয়মিতসাম্রাজ্য";
import Class12সাম্রাজ্যবাদেরবিরুদ্ধেপ্রতিক্রিয়াPage from "./containers/Class12/ইতিহাস-উচ্চমাধ্যমিকপ্রস্তুতি/সাম্রাজ্যবাদেরবিরুদ্ধেপ্রতিক্রিয়া";
import Class12ঔপনিবেশিকভারতেরশাসনPage from "./containers/Class12/ইতিহাস-উচ্চমাধ্যমিকপ্রস্তুতি/ঔপনিবেশিকভারতেরশাসন";
import Class12দ্বিতীয়বিশ্বযুদ্ধওউপনিবেশসমূহPage from "./containers/Class12/ইতিহাস-উচ্চমাধ্যমিকপ্রস্তুতি/দ্বিতীয়বিশ্বযুদ্ধওউপনিবেশসমূহ";
import Class12ঠান্ডালড়াইয়েরযুগPage from "./containers/Class12/ইতিহাস-উচ্চমাধ্যমিকপ্রস্তুতি/ঠান্ডালড়াইয়েরযুগ";
import Class12অবউপনিবেশীকরণPage from "./containers/Class12/ইতিহাস-উচ্চমাধ্যমিকপ্রস্তুতি/অবউপনিবেশীকরণ";

import Class12Computerউচ্চমাধ্যমিকপ্রস্তুতিPage from "./containers/Class12/Computer-উচ্চমাধ্যমিকপ্রস্তুতি";
import Class12LogicGateandCombinationalCircuitPage from "./containers/Class12/Computer-উচ্চমাধ্যমিকপ্রস্তুতি/LogicGateandCombinationalCircuit";
import Class12NetworkingPage from "./containers/Class12/Computer-উচ্চমাধ্যমিকপ্রস্তুতি/Networking";
import Class12DatabaseManagementSystemPage from "./containers/Class12/Computer-উচ্চমাধ্যমিকপ্রস্তুতি/DatabaseManagementSystem";
import Class12MSExcel2007Page from "./containers/Class12/Computer-উচ্চমাধ্যমিকপ্রস্তুতি/MSExcel2007";
import Class12MSAccess2007Page from "./containers/Class12/Computer-উচ্চমাধ্যমিকপ্রস্তুতি/MSAccess2007";

import EnglishGrammarArticlesHomePage from "./containers/EnglishGrammar/Articles/Loadable";
import EnglishGrammarArticlesPage from "./containers/EnglishGrammar/Articles/Articles/Loadable";
import EnglishGrammarBeginnerMockTestPage from "./containers/EnglishGrammar/Articles/BeginnerMockTest/Loadable";
import EnglishGrammarIntermediateMockTestPage from "./containers/EnglishGrammar/Articles/IntermediateMockTest/Loadable";
import EnglishGrammarAdvancedMockTestPage from "./containers/EnglishGrammar/Articles/AdvancedMockTest/Loadable";

import EnglishGrammarGenderPage from "./containers/EnglishGrammar/Gender/Loadable";
import EnglishGrammarIntroductionPage from "./containers/EnglishGrammar/Gender/Introduction/Loadable";
import EnglishGrammarMasculinePage from "./containers/EnglishGrammar/Gender/Masculine/Loadable";
import EnglishGrammarFemininePage from "./containers/EnglishGrammar/Gender/Feminine/Loadable";
import EnglishGrammarNeuterPage from "./containers/EnglishGrammar/Gender/Neuter/Loadable";
import EnglishGrammarCommonPage from "./containers/EnglishGrammar/Gender/Common/Loadable";
import EnglishGrammarRulesToChangeGenderPage from "./containers/EnglishGrammar/Gender/RulesToChangeGender/Loadable";
import EnglishGrammarGenderBeginnerMockTestPage from "./containers/EnglishGrammar/Gender/BeginnerMockTest/Loadable";
import EnglishGrammarGenderIntermediateMockTestPage from "./containers/EnglishGrammar/Gender/IntermediateMockTest/Loadable";
import EnglishGrammarGenderAdvancedMockTestPage from "./containers/EnglishGrammar/Gender/AdvancedMockTest/Loadable";

import EnglishGrammarPunctuationPage from "./containers/EnglishGrammar/Punctuation/Loadable";
import EnglishGrammarPunctuationIntroductionPage from "./containers/EnglishGrammar/Punctuation/Introduction/Loadable";

import EnglishGrammarVocabularyPage from "./containers/EnglishGrammar/Vocabulary/Loadable";
import EnglishGrammarSubjectandPredicatePage from "./containers/EnglishGrammar/Vocabulary/SubjectandPredicate/Loadable";
import EnglishGrammarSynonymsandAntonymsPage from "./containers/EnglishGrammar/Vocabulary/SynonymsandAntonyms/Loadable";
import EnglishGrammarVowelsandConsonantsPage from "./containers/EnglishGrammar/Vocabulary/VowelsandConsonants/Loadable";
import EnglishGrammarSilentLettersPage from "./containers/EnglishGrammar/Vocabulary/SilentLetters/Loadable";
import EnglishGrammarSyllablesPage from "./containers/EnglishGrammar/Vocabulary/Syllables/Loadable";
import EnglishGrammarCollocationsPage from "./containers/EnglishGrammar/Vocabulary/Collocations/Loadable";
import EnglishGrammarContractedFormsPage from "./containers/EnglishGrammar/Vocabulary/ContractedForms/Loadable";

import EnglishGrammarSentencePage from "./containers/EnglishGrammar/Sentence/Loadable";
import EnglishGrammarSentenceIntroductionPage from "./containers/EnglishGrammar/Sentence/Introduction/Loadable";
import EnglishGrammarSimpleSentencePage from "./containers/EnglishGrammar/Sentence/SimpleSentence/Loadable";
import EnglishGrammarComplexSentencePage from "./containers/EnglishGrammar/Sentence/ComplexSentence/Loadable";
import EnglishGrammarCompoundSentencePage from "./containers/EnglishGrammar/Sentence/CompoundSentence/Loadable";
import EnglishGrammarCompoundcomplexSentencePage from "./containers/EnglishGrammar/Sentence/CompoundcomplexSentence/Loadable";
import EnglishGrammarAssertiveSentencePage from "./containers/EnglishGrammar/Sentence/AssertiveSentence/Loadable";
import EnglishGrammarImperativeSentencePage from "./containers/EnglishGrammar/Sentence/ImperativeSentence/Loadable";
import EnglishGrammarOperativeSentencePage from "./containers/EnglishGrammar/Sentence/OperativeSentence/Loadable";
import EnglishGrammarExclamatorySentencePage from "./containers/EnglishGrammar/Sentence/ExclamatorySentence/Loadable";
import EnglishGrammarInterrogativeSentencePage from "./containers/EnglishGrammar/Sentence/InterrogativeSentence/Loadable";
import EnglishGrammarSentenceBeginnerMockTestPage from "./containers/EnglishGrammar/Sentence/BeginnerMockTest/Loadable";
import EnglishGrammarSentenceIntermediateMockTestPage from "./containers/EnglishGrammar/Sentence/IntermediateMockTest/Loadable";
import EnglishGrammarSentenceAdvancedMockTestPage from "./containers/EnglishGrammar/Sentence/AdvancedMockTest/Loadable";

import EnglishGrammarNounsPage from "./containers/EnglishGrammar/Nouns/Loadable";
import EnglishGrammarNounsIntroductionPage from "./containers/EnglishGrammar/Nouns/Introduction/Loadable";
import EnglishGrammarProperNounPage from "./containers/EnglishGrammar/Nouns/ProperNoun/Loadable";
import EnglishGrammarCommonNounPage from "./containers/EnglishGrammar/Nouns/CommonNoun/Loadable";
import EnglishGrammarCollectiveNounPage from "./containers/EnglishGrammar/Nouns/CollectiveNoun/Loadable";
import EnglishGrammarMaterialNounPage from "./containers/EnglishGrammar/Nouns/MaterialNoun/Loadable";
import EnglishGrammarAbstractNounPage from "./containers/EnglishGrammar/Nouns/AbstractNoun/Loadable";
import EnglishGrammarCountableNounPage from "./containers/EnglishGrammar/Nouns/CountableNoun/Loadable";
import EnglishGrammarUncountableNounPage from "./containers/EnglishGrammar/Nouns/UncountableNoun/Loadable";
import EnglishGrammarNounsBeginnerMockTestPage from "./containers/EnglishGrammar/Nouns/BeginnerMockTest/Loadable";
import EnglishGrammarNounsIntermediateMockTestPage from "./containers/EnglishGrammar/Nouns/IntermediateMockTest/Loadable";
import EnglishGrammarNounsAdvancedMockTestPage from "./containers/EnglishGrammar/Nouns/AdvancedMockTest/Loadable";

import EnglishGrammarAdjectivesPage from "./containers/EnglishGrammar/Adjectives/Loadable";
import EnglishGrammarAdjectivesIntroductionPage from "./containers/EnglishGrammar/Adjectives/Introduction/Loadable";
import EnglishGrammarDescriptiveAdjectivesPage from "./containers/EnglishGrammar/Adjectives/DescriptiveAdjectives/Loadable";
import EnglishGrammarQuantitativeAdjectivesPage from "./containers/EnglishGrammar/Adjectives/QuantitativeAdjectives/Loadable";
import EnglishGrammarDemonstrativeAdjectivesPage from "./containers/EnglishGrammar/Adjectives/DemonstrativeAdjectives/Loadable";
import EnglishGrammarPossessiveAdjectivesPage from "./containers/EnglishGrammar/Adjectives/PossessiveAdjectives/Loadable";
import EnglishGrammarInterrogativeAdjectivesPage from "./containers/EnglishGrammar/Adjectives/InterrogativeAdjectives/Loadable";
import EnglishGrammarNumeralAdjectivesPage from "./containers/EnglishGrammar/Adjectives/NumeralAdjectives/Loadable";
import EnglishGrammarExclamatoryAdjectivesPage from "./containers/EnglishGrammar/Adjectives/ExclamatoryAdjectives/Loadable";
import EnglishGrammarAdjectivesBeginnerMockTestPage from "./containers/EnglishGrammar/Adjectives/BeginnerMockTest/Loadable";
import EnglishGrammarAdjectivesIntermediateMockTestPage from "./containers/EnglishGrammar/Adjectives/IntermediateMockTest/Loadable";
import EnglishGrammarAdjectivesAdvancedMockTestPage from "./containers/EnglishGrammar/Adjectives/AdvancedMockTest/Loadable";

import Class10BengaliPage from "./containers/BaseLine/Class10Bengali";
import Class10BengaliGhanChakkuPage from "./containers/BaseLine/Class10Bengali/Class10BengaliGhanChakku";
import FooterComponent from "./components/FooterComponent";
import PrivacyPage from "./containers/PrivacyPolicy";
import WestBengalPrivacyPolicyPage from "./containers/WestBengalPrivacyPolicy";
import WestBengalNewPrivacyPolicyPage from "./containers/WestBengalPrivacyPolicy/wb";
import CbseClass10PolicyPage from "./containers/CbsePrivacyPolicy/class10";
import CbseClass8PolicyPage from "./containers/CbsePrivacyPolicy/class8";
import CbseClass6PolicyPage from "./containers/CbsePrivacyPolicy/class6";
import CbseClass7PolicyPage from "./containers/CbsePrivacyPolicy/class7";
import GeneralKnowledgePolicyPage from "./containers/PrivacyPolicy/generalKnowledge";
import CBSEPolicyPage from "./containers/CbsePrivacyPolicy";
import "./index.css";

const theme = createTheme();

const sections = [
  { title: "Technology", url: "#" },
  { title: "Design", url: "#" },
  { title: "Culture", url: "#" },
  { title: "Business", url: "#" },
  { title: "Politics", url: "#" },
  { title: "Opinion", url: "#" },
];

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      <Container maxWidth="lg">
        {/* <Header title="QList" sections={sections} /> */}

        <Router>
          <NavbarPage />
          <Routes static={true}>
            <Route path="*" element={<HomePage />} />
            <Route exact path="/PrivacyPolicy" element={<PrivacyPage />} />
            <Route exact path="/AboutUs" element={<AboutUsPage />} />
            <Route
              exact
              path="WestBengal/PrivacyPolicy"
              element={<WestBengalPrivacyPolicyPage />}
            />
            <Route
              exact
              path="QList/PrivacyPolicy"
              element={<WestBengalNewPrivacyPolicyPage />}
            />
            <Route
              exact
              path="CBSE/Class10/PrivacyPolicy"
              element={<CbseClass10PolicyPage />}
            />
            <Route
              exact
              path="CBSE/Class8/PrivacyPolicy"
              element={<CbseClass8PolicyPage />}
            />
            <Route
              exact
              path="CBSE/Class6/PrivacyPolicy"
              element={<CbseClass6PolicyPage />}
            />
            <Route
              exact
              path="CBSE/Class7/PrivacyPolicy"
              element={<CbseClass7PolicyPage />}
            />
            <Route
              exact
              path="General-Knowledge/PrivacyPolicy"
              element={<GeneralKnowledgePolicyPage />}
            />
            <Route
              exact
              path="CBSE/PrivacyPolicy"
              element={<CBSEPolicyPage />}
            />
            <Route
              exact
              path="/BaseLine-Guide"
              element={<ClassBaseLinePage />}
            />
            <Route
              exact
              path="/West-Bengal-Class-10-Guide"
              element={<Class10Page />}
            />
            <Route
              exact
              path="/West-Bengal-Class-10-Bengali-Guide"
              element={<Class10BengaliPage />}
            />
            <Route
              exact
              path="/West-Bengal-Class-10-Bengali-Guide/GhanChakku"
              element={<Class10BengaliGhanChakkuPage />}
            />
            <Route
              exact
              encodeURIComponent
              path="/West-Bengal-Class-10-English-Madhyamik-Guide"
              element={<EnglishPage />}
            />
            <Route
              exact
              encodeURIComponent
              path="/West-Bengal-Class-10-Bengali-Madhyamik-Guide"
              element={<BengaliPage />}
            />
            <Route
              exact
              encodeURIComponent
              path="/West-Bengal-Class-10-Physical-Science-Madhyamik-Guide"
              element={<PhysicalSciencePage />}
            />
            {/* <Route
              exact
              path="/English-মাধ্যমিক-প্রস্তুতি-Guide"
              element={<Class10Englishমাধ্যমিকপ্রস্তুতিPage />}
            /> */}
            <Route
              exact
              path="/English-Madhyamik-Preparation-Fathers-Help-Guide"
              element={<Class10FathersHelpPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Fable-Guide"
              element={<Class10FablePage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-The-Passing-Away-of-Bapu-Guide"
              element={<Class10ThePassingAwayofBapuPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-My-Own-True-Family-Guide"
              element={<Class10MyOwnTrueFamilyPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Our-Runaway-Kite-Guide"
              element={<Class10OurRunawayKitePage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Sea-Fever-Guide"
              element={<Class10SeaFeverPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-The-Cat-Guide"
              element={<Class10TheCatPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-The-Snail-Guide"
              element={<Class10TheSnailPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Paragraph-Guide"
              element={<Class10ParagraphPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Processing/Flow-Chart-Guide"
              element={<Class10ProcessingFlowChartPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Report-Guide"
              element={<Class10ReportPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Letter-Writing-Guide"
              element={<Class10LetterWritingPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-NOTICE-WRITING-Guide"
              element={<Class10NOTICEWRITINGPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Story-Writing-Guide"
              element={<Class10StoryWritingPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Articles-and-Preposition-Guide"
              element={<Class10ArticlesandPrepositionPage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Voice-change-Guide"
              element={<Class10VoicechangePage />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Previous-year-question-paper-2017-Guide"
              element={<Class10Previousyearquestionpaper2017Page />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Previous-year-question-paper-2018-Guide"
              element={<Class10Previousyearquestionpaper2018Page />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Previous-year-question-paper-2019-Guide"
              element={<Class10Previousyearquestionpaper2019Page />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Previous-year-question-paper-2020-Guide"
              element={<Class10Previousyearquestionpaper2020Page />}
            />
            <Route
              exact
              path="/English-Madhyamik-Preparation-Previous-year-question-paper-2023-Guide"
              element={<Class10Previousyearquestionpaper2023Page />}
            />
            {/* <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-অভিষেক-Guide"
              element={<অভিষেকPage />}
            />

            <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-অসুখী-একজন-Guide"
              element={<অসুখীএকজনPage />}
            />
            <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-আফ্রিকা-Guide"
              element={<আফ্রিকাPage />}
            />
            <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-আয়-আরো-বেঁধে-বেঁধে-থাকি-Guide"
              element={<আয়আরোবেঁধেবেঁধেথাকিPage />}
            />
            <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-জ্ঞানচক্ষু-Guide"
              element={<জ্ঞানচক্ষুPage />}
            />
            <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-প্রবন্ধ-রচনা-Guide"
              element={<প্রবন্ধরচনাPage />}
            />
            <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-প্রলয়োল্লাস-Guide"
              element={<প্রলয়োল্লাসPage />}
            />
            <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-বহুরূপী-Guide"
              element={<বহুরূপীPage />}
            />
            <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-বাচ্য-পরিবর্তন-Guide"
              element={<বাচ্যপরিবর্তনPage />}
            />
            <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-সিরাজদৌল্লা-Guide"
              element={<সিরাজদৌল্লাPage />}
            />
            <Route
              exact
              path="/বাংলা-মাধ্যমিক-প্রস্তুতি-হারিয়ে-যাওয়া-কালি-কলম-Guide"
              element={<হারিয়েযাওয়াকালিকলমPage />}
            /> */}
            {/* <Route
              exact
              path="/bengali-madhyamik-preparation-Guide"
              element={<Class10বাংলামাধ্যমিকপ্রস্তুতিPage />}
            /> */}
            <Route
              exact
              path="/bengali-madhyamik-preparation-gyan-chokkhu-guide"
              element={<Class10জ্ঞানচক্ষুPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-asukhi-ekjon-guide"
              element={<Class10অসুখীএকজনPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-ai-aro-bedhe-bedhe-thaki-guide"
              element={<Class10আয়আরোবেঁধেবেঁধেথাকিPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Africa-guide"
              element={<Class10আফ্রিকাPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-bohurupi-guide"
              element={<Class10বহুরূপীPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-hariye-jaowa-kali-kolom-guide"
              element={<Class10হারিয়েযাওয়াকালিকলমPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-sirajdullah-guide"
              element={<Class10সিরাজদৌল্লাPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-abhisek-guide"
              element={<Class10অভিষেকPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-proloy-ollas-guide"
              element={<Class10প্রলয়োল্লাসPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-potherdabi-guide"
              element={<Class10পথেরদাবীPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-sindhu-tire-guide"
              element={<Class10সিন্ধুতীরেPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-bangla-vasar-biggan-guide"
              element={<Class10বাংলাভাষায়বিজ্ঞানPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-adalbdal-guide"
              element={<Class10অদলবদলPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-astrer-birudhe-gan-guide"
              element={<Class10অস্ত্রেরবিরুদ্ধেগানPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-nodir-bidroho-guide"
              element={<Class10নদীরবিদ্রোহPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-koni-guide"
              element={<Class10কোনিPage />}
            />
            {/* <Route
              exact
              path="/bengali-madhyamik-preparation-bachya-poriborton-guide"
              element={<Class10বাক্যপরিবর্তনPage />}
            /> */}
            <Route
              exact
              path="/bengali-madhyamik-preparation-prabondo-rochona-guide"
              element={<Class10প্রতিবেদনরচনাPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-bachya-poriborton-guide"
              element={<Class10বাচ্যপরিবর্তনPage />}
            />
            {/* <Route
              exact
              path="/bengali-madhyamik-preparation-prabondo-rochona-guide"
              element={<Class10প্রবন্ধরচনাPage />}
            /> */}
            <Route
              exact
              path="/bengali-madhyamik-preparation-karok-o-akarok-somporko-guide"
              element={<Class10কারকওঅকারকসম্পর্কPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-somas-guide"
              element={<Class10সমাসPage />}
            />
            {/* <Route
              exact
              path="/bengali-madhyamik-preparation-সংলাপ-Guide"
              element={<Class10সংলাপPage />}
            /> */}
            <Route
              exact
              path="/bengali-madhyamik-preparation-bonganubad-guide"
              element={<Class10বঙ্গানুবাদPage />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Madhyamik-Mock-Test-1-Guide"
              element={<Class10MadhyamikMockTest1Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Madhyamik-Mock-Test-2-Guide"
              element={<Class10MadhyamikMockTest2Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Madhyamik-Mock-Test-3-Guide"
              element={<Class10MadhyamikMockTest3Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Madhyamik-Mock-Test-4-Guide"
              element={<Class10MadhyamikMockTest4Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Madhyamik-Mock-Test-5-Guide"
              element={<Class10MadhyamikMockTest5Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Madhyamik-Mock-Test-6-Guide"
              element={<Class10MadhyamikMockTest6Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Madhyamik-Mock-Test-7-Guide"
              element={<Class10MadhyamikMockTest7Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Madhyamik-Mock-Test-8-Guide"
              element={<Class10MadhyamikMockTest8Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Madhyamik-Mock-Test-9-Guide"
              element={<Class10MadhyamikMockTest9Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Madhyamik-Mock-Test-10-Guide"
              element={<Class10MadhyamikMockTest10Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Previous-Year-Question-Paper-2017-Guide"
              element={<Class10PreviousYearQuestionPaper2017Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Previous-Year-Question-Paper-2018-Guide"
              element={<Class10PreviousYearQuestionPaper2018Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Previous-Year-Question-Paper-2019-Guide"
              element={<Class10PreviousYearQuestionPaper2019Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Previous-Year-Question-Paper-2020-Guide"
              element={<Class10PreviousYearQuestionPaper2020Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Previous-Year-Question-Paper-2022-Guide"
              element={<Class10PreviousYearQuestionPaper2022Page />}
            />
            <Route
              exact
              path="/bengali-madhyamik-preparation-Previous-Year-Question-Paper-2023-Guide"
              element={<Class10PreviousYearQuestionPaper2023Page />}
            />

            <Route
              exact
              path="/physical-science-madhyamik-preparation-ayonio-o-somojoji-bondhon-guide"
              element={<আয়নীয়ওসমযোজীবন্ধনPage />}
            />
            <Route
              exact
              path="/physical-science-madhyamik-preparation-aloo-guide"
              element={<আলোPage />}
            />
            <Route
              exact
              path="/physical-science-madhyamik-preparation-geyser-achoron-guide"
              element={<গ্যাসেরআচরণPage />}
            />
            <Route
              exact
              path="/physical-science-madhyamik-preparation-choltorit-guide"
              element={<চলতড়িৎPage />}
            />
            <Route
              exact
              path="/physical-science-madhyamik-preparation-taper-ghotona-somuho-guide"
              element={<তাপেরঘটনাসমূহPage />}
            />
            <Route
              exact
              path="/physical-science-madhyamik-preparation-poromanu-niuklias-guide"
              element={<পরমাণুরনিউক্লিয়াসPage />}
            />
            <Route
              exact
              path="/physical-science-madhyamik-preparation-poribeser-jonno-vabna-guide"
              element={<পরিবেশেরজন্যভাবনাPage />}
            />
            <Route
              exact
              path="/physical-science-madhyamik-preparation-porjai-saroni-ebong-mouloder-dhormer-porjabrittota-guide"
              element={<পর্যায়সারণিএবংমৌলদেরধর্মেরপর্যাবৃত্ততাPage />}
            />
            <Route
              exact
              path="/physical-science-madhyamik-preparation-rasaonik-gonona-guide"
              element={<রাসায়নিকগণনাPage />}
            />

            <Route
              exact
              path="/West-Bengal-Class-10-Geography-Madhyamik-Guide"
              element={<ভূগোলমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/geography-madhyamik-preparation-bohirjat-prokriya-o-tader-dara-sristo-bhumirup-guide"
              element={<বহির্জাতপ্রক্রিয়াওতাদেরদ্বারাসৃষ্টভূমিরূপPage />}
            />
            <Route
              exact
              path="/geography-madhyamik-preparation-bayumondal-guide"
              element={<বায়ুমণ্ডলPage />}
            />
            <Route
              exact
              path="/geography-madhyamik-preparation-barimondal-guide"
              element={<বারিমণ্ডলPage />}
            />
            <Route
              exact
              path="/geography-madhyamik-preparation-borjyo-babosthapona-guide"
              element={<বর্জ্যব্যবস্থাপনাPage />}
            />
            <Route
              exact
              path="/geography-madhyamik-preparation-varoter-prakritik-poribesh-guide"
              element={<ভারতেরপ্রাকৃতিকপরিবেশPage />}
            />
            <Route
              exact
              path="/geography-madhyamik-preparation-varoter-arthonoitik-poribesh-guide"
              element={<ভারতেরঅর্থনৈতিকপরিবেশPage />}
            />
            <Route
              exact
              path="/geography-madhyamik-preparation-upogroho-chitro-o-bhuboichitra-suchak-manchitra-guide"
              element={<উপগ্রহচিত্রওভূবৈচিত্র্যসূচকমানচিত্রPage />}
            />
            <Route
              exact
              path="geography-madhyamik-preparation-Mock-Test-1-Guide"
              element={<MockTest1Page />}
            />
            <Route
              exact
              path="geography-madhyamik-preparation-Mock-Test-2-Guide"
              element={<MockTest2Page />}
            />
            <Route
              exact
              path="geography-madhyamik-preparation-Mock-Test-3-Guide"
              element={<MockTest3Page />}
            />
            <Route
              exact
              path="geography-madhyamik-preparation-Mock-Test-4-Guide"
              element={<MockTest4Page />}
            />
            <Route
              exact
              path="geography-madhyamik-preparation-Previous-Year-Question-Paper-2017-Guide"
              element={<PreviousYearQuestionPaper2017Page />}
            />
            <Route
              exact
              path="geography-madhyamik-preparation-Previous-Year-Question-Paper-2018-Guide"
              element={<PreviousYearQuestionPaper2018Page />}
            />
            <Route
              exact
              path="geography-madhyamik-preparation-Previous-Year-Question-Paper-2019-Guide"
              element={<PreviousYearQuestionPaper2019Page />}
            />
            <Route
              exact
              path="geography-madhyamik-preparation-Previous-Year-Question-Paper-2020-Guide"
              element={<PreviousYearQuestionPaper2020Page />}
            />
            <Route
              exact
              path="geography-madhyamik-preparation-Previous-Year-Question-Paper-2022-Guide"
              element={<PreviousYearQuestionPaper2022Page />}
            />
            <Route
              exact
              path="geography-madhyamik-preparation-Previous-Year-Question-Paper-2023-Guide"
              element={<PreviousYearQuestionPaper2023Page />}
            />

            <Route
              exact
              path="/West-Bengal-Class-10-Life-Science-Madhyamik-Guide"
              element={<জীবনবিজ্ঞানমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-jibjogote-niyontron-o-sommonoi-guide"
              element={<জীবজগতেনিয়ন্ত্রণওসমন্বয়Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-jiboner-probahomanota-guide"
              element={<জীবনেরপ্রবাহমানতাPage />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-bongsogoti-ebong-koyekti-sadharon-jingoto-rog-guide"
              element={<বংশগতিএবংকয়েকটিসাধারণজিনগতরোগPage />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-abhibyakti-o-abhijojon-guide"
              element={<অভিব্যক্তিওঅভিযোজনPage />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-poribesh-o-tar-sompod ebong-tader-songrokkhon-guide"
              element={<পরিবেশওতারসম্পদএবংতাদেরসংরক্ষণPage />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Madhyamik-Mock-Test-1-Guide"
              element={<LifeScienceMadhyamikMockTest1Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Madhyamik-Mock-Test-2-Guide"
              element={<LifeScienceMadhyamikMockTest2Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Madhyamik-Mock-Test-3-Guide"
              element={<LifeScienceMadhyamikMockTest3Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Madhyamik-Mock-Test-4-Guide"
              element={<LifeScienceMadhyamikMockTest4Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Madhyamik-Mock-Test-5-Guide"
              element={<LifeScienceMadhyamikMockTest5Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Madhyamik-Mock-Test-6-Guide"
              element={<LifeScienceMadhyamikMockTest6Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Madhyamik-Mock-Test-7-Guide"
              element={<LifeScienceMadhyamikMockTest7Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Madhyamik-Mock-Test-8-Guide"
              element={<LifeScienceMadhyamikMockTest8Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Previous-Year-Question-Paper-2017-Guide"
              element={<LifeSciencePreviousYearQuestionPaper2017Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Previous-Year-Question-Paper-2018-Guide"
              element={<LifeSciencePreviousYearQuestionPaper2018Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Previous-Year-Question-Paper-2020-Guide"
              element={<LifeSciencePreviousYearQuestionPaper2020Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Previous-Year-Question-Paper-2022-Guide"
              element={<LifeSciencePreviousYearQuestionPaper2022Page />}
            />
            <Route
              exact
              path="/life-science-madhyamik-preparation-Previous-Year-Question-Paper-2023-Guide"
              element={<LifeSciencePreviousYearQuestionPaper2023Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Guide"
              element={<ইতিহাসমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-itihaser-dharona-Guide"
              element={<ইতিহাসেরধারণাPage />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-sanskar-boishistyo-o-mulyaon-guide"
              element={<সংস্কারবৈশিষ্ট্যওমূল্যায়নPage />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-protirodh-o-bidroho-boishistyo-o-bishleson-guide"
              element={<প্রতিরোধওবিদ্রোহবৈশিষ্ট্যওবিশ্লেষণPage />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-songhobodho-tar-gorar-kotha-bishleson-o-boishityo-guide"
              element={<সংঘবদ্ধতারগোড়ারকথাবিশ্লেষণওবৈশিষ্ট্যPage />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-bikolpo-chinta-o-udyogoboishistyo-o-porjalochona-guide"
              element={<বিকল্পচিন্তাওউদ্যোগবৈশিষ্ট্যওপর্যালোচনাPage />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-binso-shotoker-varote-krisoker-shromik-o-bamponthi-andolon-boishistyo-o-porjalochon-guide"
              element={
                <বিংশশতকেরভারতেকৃষকশ্রমিকওবামপন্থীআন্দোলনবৈশিষ্ট্যওপর্যালোচনাPage />
              }
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-binso-shotoker-varoter-nari-chatra-o-prantik-jonogosthir-andolon-boishistyo-o-bisleson-guide"
              element={
                <বিংশশতকেরভারতেরনারীছাত্রওপ্রান্তিকজনগোষ্ঠীরআন্দোলনবৈশিষ্ট্যওবিশ্লেষণPage />
              }
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-uttor-oiponibeshik-varot-bish-shotoker-ditiyo-porbo-guide"
              element={<উত্তরঔপনিবেশিকভারতবিশশতকেরদ্বিতীয়পর্ব১৯৪৭১৯৬৪Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-1-Guide"
              element={<HistoryMadhyamikMockTest1Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-2-Guide"
              element={<HistoryMadhyamikMockTest2Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-3-Guide"
              element={<HistoryMadhyamikMockTest3Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-4-Guide"
              element={<HistoryMadhyamikMockTest4Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-5-Guide"
              element={<HistoryMadhyamikMockTest5Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-6-Guide"
              element={<HistoryMadhyamikMockTest6Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-7-Guide"
              element={<HistoryMadhyamikMockTest7Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-8-Guide"
              element={<HistoryMadhyamikMockTest8Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-9-Guide"
              element={<HistoryMadhyamikMockTest9Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-10-Guide"
              element={<HistoryMadhyamikMockTest10Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Madhyamik-Mock-Test-11-(50-numbers)-Guide"
              element={<HistoryMadhyamikMockTest1150numbersPage />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Previous-Year-Question-Paper-2017-Guide"
              element={<HistoryPreviousYearQuestionPaper2017Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Previous-Year-Question-Paper-2018-Guide"
              element={<HistoryPreviousYearQuestionPaper2018Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Previous-Year-Question-Paper-2019-Guide"
              element={<HistoryPreviousYearQuestionPaper2019Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Previous-Year-Question-Paper-2020-Guide"
              element={<HistoryPreviousYearQuestionPaper2020Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Previous-Year-Question-Paper-2022-Guide"
              element={<HistoryPreviousYearQuestionPaper2022Page />}
            />
            <Route
              exact
              path="/History-Madhyamik-Preparation-Previous-Year-Question-Paper-2023-Guide"
              element={<HistoryPreviousYearQuestionPaper2023Page />}
            />

            <Route
              exact
              path="/West-Bengal-Class-9-Guide"
              element={<Class9Page />}
            />
            <Route
              exact
              path="/Bengali-Class-9-Guide"
              element={<Class9বাংলাক্লাস৯Page />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-কলিঙ্গদেশে-ঝড়-বৃষ্টি-Guide"
              element={<Class9কলিঙ্গদেশেঝড়বৃষ্টিPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-নোঙর-Guide"
              element={<Class9নোঙরPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-খেয়া-Guide"
              element={<Class9খেয়াPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-আকাশে-সাতটি-তারা-Guide"
              element={<Class9আকাশেসাতটিতারাPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-আবহমান-Guide"
              element={<Class9আবহমানPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-ভাঙার-গান-Guide"
              element={<Class9ভাঙারগানPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-আমরা-Guide"
              element={<Class9আমরাPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-ইলিয়াস-Guide"
              element={<Class9ইলিয়াসPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-দাম-Guide"
              element={<Class9দামPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-নিরুদ্দেশ-Guide"
              element={<Class9নিরুদ্দেশPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-রাধারাণী-Guide"
              element={<Class9রাধারাণীPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-চন্দ্ৰনাথ-Guide"
              element={<Class9চন্দ্ৰনাথPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-নব-নব-সৃষ্টি-Guide"
              element={<Class9নবনবসৃষ্টিPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-হিমালয়-দর্শন-Guide"
              element={<Class9হিমালয়দর্শনPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-চিঠি-Guide"
              element={<Class9চিঠিPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-ধীবর-বৃত্তান্ত-Guide"
              element={<Class9ধীবরবৃত্তান্তPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-ব্যোমযাত্রীর-ডায়রি-Guide"
              element={<Class9ব্যোমযাত্রীরডায়রিPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-কর্ভাস-Guide"
              element={<Class9কর্ভাসPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-স্বর্ণপণী-Guide"
              element={<Class9স্বর্ণপণীPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৯-First-Unit-Test-Guide"
              element={<Class9FirstUnitTestPage />}
            />

            <Route
              exact
              path="/English-Class-9-Guide"
              element={<Class9Englishক্লাস৯Page />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-Tales-of-Bhola-Grandpa-Guide"
              element={<Class9TalesofBholaGrandpaPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-All-about-a-Dog-Guide"
              element={<Class9AllaboutaDogPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-Autumn-Guide"
              element={<Class9AutumnPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-A-Day-in-the-Zoo-Guide"
              element={<Class9ADayintheZooPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-All-Summer-in-a-Day-Guide"
              element={<Class9AllSummerinaDayPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-Mild-the-Mist-upon-the-Hill-Guide"
              element={<Class9MildtheMistupontheHillPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-Tom-Loses-a-Tooth-Guide"
              element={<Class9TomLosesaToothPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-His-First-Flight-Guide"
              element={<Class9HisFirstFlightPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-The-North-Ship-Guide"
              element={<Class9TheNorthShipPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-The-Price-of-Bananas-Guide"
              element={<Class9ThePriceofBananasPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-A-Shipwrecked-Sailor-Guide"
              element={<Class9AShipwreckedSailorPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-Hunting-Snake-Guide"
              element={<Class9HuntingSnakePage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৯-Text-all-writing-skills-Guide"
              element={<Class9TextallwritingskillsPage />}
            />
            <Route
              exact
              path="/History-Class-9-Guide"
              element={<Class9ইতিহাসক্লাস৯Page />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৯-ফরাসি-বিপ্লবের-কয়েকটি-দিক-Guide"
              element={<Class9ফরাসিবিপ্লবেরকয়েকটিদিকPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৯-বিপ্লবী-আদৰ্শ,-নেপোলিয়নীয়-সাম্রাজ্য-ও-জাতীয়তাবাদ-Guide"
              element={
                <Class9বিপ্লবীআদৰ্শনেপোলিয়নীয়সাম্রাজ্যওজাতীয়তাবাদPage />
              }
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৯-ঊনবিংশ-শতকের-ইউরোপ:-রাজতান্ত্রিক-ও-জাতীয়তাবাদী-ভাবধারার-সংঘাত-Guide"
              element={
                <Class9ঊনবিংশশতকেরইউরোপরাজতান্ত্রিকওজাতীয়তাবাদীভাবধারারসংঘাতPage />
              }
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৯-শিল্পবিপ্লব,-উপনিবেশবাদ-ও-সাম্রাজ্যবাদ-Guide"
              element={<Class9শিল্পবিপ্লবউপনিবেশবাদওসাম্রাজ্যবাদPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৯-বিশ-শতকে-ইউরোপ-Guide"
              element={<Class9বিশশতকেইউরোপPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৯-দ্বিতীয়-বিশ্বযুদ্ধ-ও-তারপর-Guide"
              element={<Class9দ্বিতীয়বিশ্বযুদ্ধওতারপরPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৯-জাতিসংঘ-এবং-সম্মিলিত-জাতিপুঞ্জ-Guide"
              element={<Class9জাতিসংঘএবংসম্মিলিতজাতিপুঞ্জPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৯-First-Unit-Test-Guide"
              element={<Class9HistoryFirstUnitTestPage />}
            />
            <Route
              exact
              path="/Geography-Class-9-Guide"
              element={<Class9ভূগোলক্লাস৯Page />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৯-গ্রহরূপে-পৃথিবী-Guide"
              element={<Class9গ্রহরূপেপৃথিবীPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৯-পৃথিবীর-গতিসমূহ-Guide"
              element={<Class9পৃথিবীরগতিসমূহPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৯-পৃথিবীপৃষ্ঠে-কোনো-স্থানের-অবস্থান-নির্ণয়-Guide"
              element={<Class9পৃথিবীপৃষ্ঠেকোনোস্থানেরঅবস্থাননির্ণয়Page />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৯-ভূ-গাঠনিক-প্রক্রিয়া-এবং-পৃথিবীর-বিভিন্ন-ভূমিরূপ-Guide"
              element={<Class9ভূগাঠনিকপ্রক্রিয়াএবংপৃথিবীরবিভিন্নভূমিরূপPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৯-আবহবিকার-Guide"
              element={<Class9আবহবিকারPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৯-দুর্যোগ-ও-বিপর্যয়-Guide"
              element={<Class9দুর্যোগওবিপর্যয়Page />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৯-ভারতের-সম্পদ-Guide"
              element={<Class9ভারতেরসম্পদPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৯-পশ্চিমবঙ্গ-Guide"
              element={<Class9পশ্চিমবঙ্গPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৯-First-Unit-Test-Guide"
              element={<Class9GeographyFirstUnitTestPage />}
            />
            <Route
              exact
              path="/Life-Science-Class-9-Guide"
              element={<Class9জীবনবিজ্ঞাননবমশ্রেণিPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-নবম-শ্রেণি-জীবন-ও-তার-বৈচিত্র্য-Guide"
              element={<Class9জীবনওতারবৈচিত্র্যPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-নবম-শ্রেণি-জীবন-সংগঠনের-স্তর-Guide"
              element={<Class9জীবনসংগঠনেরস্তরPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-নবম-শ্রেণি-জৈবনিক-প্রক্রিয়া-Guide"
              element={<Class9জৈবনিকপ্রক্রিয়াPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-নবম-শ্রেণি-জীববিদ্যা-ও-মানকল্যাণ-Guide"
              element={<Class9জীববিদ্যাওমানকল্যাণPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-নবম-শ্রেণি-পরিবেশ-ও-তার-সম্পদ-Guide"
              element={<Class9পরিবেশওতারসম্পদPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-নবম-শ্রেণি-First-Unit-Test-Guide"
              element={<Class9LifeScienceFirstUnitTestPage />}
            />
            <Route
              exact
              path="/Physical-Science-Class-9-Guide"
              element={<Class9ভৌতবিজ্ঞানক্লাস৯Page />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৯-পরিমাপ-Guide"
              element={<Class9পরিমাপPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৯-বল-ও-গতি-Guide"
              element={<Class9বলওগতিPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৯-পদার্থ-:-গঠন-ও-ধর্ম-Guide"
              element={<Class9পদার্থগঠনওধর্মPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৯-পদার্থ-:-পরমাণুর-গঠন-ও-পদার্থের-ভৌত-ও-রাসায়নিক-ধর্মসমূহ-Guide"
              element={
                <Class9পদার্থপরমাণুরগঠনওপদার্থেরভৌতওরাসায়নিকধর্মসমূহPage />
              }
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৯-শক্তির-ক্রিয়া,-কার্য,-ক্ষমতা-ও-শক্তি-Guide"
              element={<Class9শক্তিরক্রিয়াকার্যক্ষমতাওশক্তিPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৯-তাপ-Guide"
              element={<Class9তাপPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৯-শব্দ-Guide"
              element={<Class9শব্দPage />}
            />

            <Route
              exact
              path="/West-Bengal-Class-8-Guide"
              element={<Class8Page />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-Guide"
              element={<Class8বাংলাক্লাস৮Page />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-বোঝাপড়া-Guide"
              element={<Class8বোঝাপড়াPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-অদ্ভুত-আতিথেয়তা-Guide"
              element={<Class8অদ্ভুতআতিথেয়তাPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-চন্দ্রগুপ্ত-Guide"
              element={<Class8চন্দ্রগুপ্তPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-বনভোজনের-ব্যাপার-Guide"
              element={<Class8বনভোজনেরব্যাপারPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-নিখিল-বঙ্গ-কবিতা-সংঘ-Guide"
              element={<Class8নিখিলবঙ্গকবিতাসংঘPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-সবুজ-জামা-Guide"
              element={<Class8সবুজজামাPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-চিঠি-Guide"
              element={<Class8চিঠিPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-আলাপ-Guide"
              element={<Class8আলাপPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-পরবাসী-Guide"
              element={<Class8পরবাসীPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-পথচলতি-Guide"
              element={<Class8পথচলতিPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-একটি-চড়ুই-পাখি-Guide"
              element={<Class8একটিচড়ুইপাখিPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-দাঁড়াও-Guide"
              element={<Class8দাঁড়াওPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-পল্লীসমাজ-Guide"
              element={<Class8পল্লীসমাজPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-ছন্নছাড়া-Guide"
              element={<Class8ছন্নছাড়াPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-গাছের-কথা-Guide"
              element={<Class8গাছেরকথাPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-হওয়ার-গান-Guide"
              element={<Class8হওয়ারগানPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-কী-করে-বুঝব-Guide"
              element={<Class8কীকরেবুঝবPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-পাড়াগাঁর-দু-পহর-ভালোবাসি-Guide"
              element={<Class8পাড়াগাঁরদুপহরভালোবাসিPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-নাটোরের-কথা-Guide"
              element={<Class8নাটোরেরকথাPage />}
            />
            {/* <Route
              exact
              path="/বাংলা-ক্লাস-৮-স্বদেশিকতা-Guide"
              element={<Class8স্বদেশিকতাPage />}
            /> */}
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-গড়াই-নদীর-তীরে-Guide"
              element={<Class8গড়াইনদীরতীরেPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-জেলখানার-চিঠি-Guide"
              element={<Class8জেলখানারচিঠিPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-স্বাধীনতা-Guide"
              element={<Class8স্বাধীনতাPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-আদাব-Guide"
              element={<Class8আদাবPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-শিকল-পরার-গান-Guide"
              element={<Class8শিকলপরারগানPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-হরিচরণ-বন্দ্যোপাধ্যায়-Guide"
              element={<Class8হরিচরণবন্দ্যোপাধ্যায়Page />}
            />
            {/* <Route
              exact
              path="/বাংলা-ক্লাস-৮-ভালোবাসা-কি-বৃথা-যায়-?-Guide"
              element={<Class8ভালোবাসাকিবৃথাযায়Page />}
            /> */}
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-ঘুরে-দাঁড়াও-Guide"
              element={<Class8ঘুরেদাঁড়াওPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-সুভা-Guide"
              element={<Class8সুভাPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-পরাজয়-Guide"
              element={<Class8পরাজয়Page />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-মাসিপিসি-Guide"
              element={<Class8মাসিপিসিPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-টিকিটের-অ্যালবাম-Guide"
              element={<Class8টিকিটেরঅ্যালবামPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-লোকটা-জানলই-না-Guide"
              element={<Class8লোকটাজানলইনাPage />}
            />
            <Route
              exact
              path="/বাংলা-ক্লাস-৮-First-Unit-Test-Guide"
              element={<Class8BengaliFirstUnitTestPage />}
            />
            <Route
              exact
              path="/Physical-Science-Class-8-Guide"
              element={<Class8ভৌতবিজ্ঞানক্লাস৮Page />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-বল-ও-চাপ-Guide"
              element={<Class8বলওচাপPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-স্পর্শ-ছাড়া-ক্রিয়াশীল-বল-Guide"
              element={<Class8স্পর্শছাড়াক্রিয়াশীলবলPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-তাপ-Guide"
              element={<Class8তাপPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-আলো-Guide"
              element={<Class8আলোPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-মৌল-যৌগ-ও-রাসায়নিক-বিক্রিয়া-পদার্থের-প্রকৃতি-Guide"
              element={<Class8মৌলযৌগওরাসায়নিকবিক্রিয়াপদার্থেরপ্রকৃতিPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-মৌল-যৌগ-ও-রাসায়নিক-বিক্রিয়া-পদার্থের-গঠন-Guide"
              element={<Class8মৌলযৌগওরাসায়নিকবিক্রিয়াপদার্থেরগঠনPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-মৌল-যৌগ-ও-রাসায়নিক-বিক্রিয়া-রাসায়নিক-বিক্রিয়া-Guide"
              element={
                <Class8মৌলযৌগওরাসায়নিকবিক্রিয়ারাসায়নিকবিক্রিয়াPage />
              }
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-মৌল-যৌগ-ও-রাসায়নিক-বিক্রিয়া-তড়িতের-রাসায়নিক-প্রভাব-Guide"
              element={
                <Class8মৌলযৌগওরাসায়নিকবিক্রিয়াতড়িতেররাসায়নিকপ্রভাবPage />
              }
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-কয়েকটি-গ্যাসের-পরিচিতি-Guide"
              element={<Class8কয়েকটিগ্যাসেরপরিচিতিPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-কার্বন-ও-কার্বনঘটিত-যৌগ-Guide"
              element={<Class8কার্বনওকার্বনঘটিতযৌগPage />}
            />
            <Route
              exact
              path="/ভৌতবিজ্ঞান-ক্লাস-৮-প্রাকৃতিক-ঘটনা-ও-তার-বিশ্লেষণ-Guide"
              element={<Class8প্রাকৃতিকঘটনাওতারবিশ্লেষণPage />}
            />
            <Route
              exact
              path="/Geography-Class-8-Guide"
              element={<Class8ভূগোলক্লাস৮Page />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-পৃথিবীর-অন্দরমহল-Guide"
              element={<Class8পৃথিবীরঅন্দরমহলPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-অস্থিত-পৃথিবী-Guide"
              element={<Class8অস্থিতপৃথিবীPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-শিলা-Guide"
              element={<Class8শিলাPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-চাপবলয়-ও-বায়ুপ্রবাহ-Guide"
              element={<Class8চাপবলয়ওবায়ুপ্রবাহPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-মেঘ-বৃষ্টি-Guide"
              element={<Class8মেঘবৃষ্টিPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-জলবায়ু-অঞ্চল-Guide"
              element={<Class8জলবায়ুঅঞ্চলPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-মানুষের-কার্যাবলি-ও-পরিবেশের-অবনমন-Guide"
              element={<Class8মানুষেরকার্যাবলিওপরিবেশেরঅবনমনPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-ভারতের-প্রতিবেশী-দেশসমূহ-ও-সঙ্গে-সম্পর্ক-Guide"
              element={<Class8ভারতেরপ্রতিবেশীদেশসমূহওসঙ্গেসম্পর্কPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-উত্তর-আমেরিকা-Guide"
              element={<Class8উত্তরআমেরিকাPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-দক্ষিণ-আমেরিকা-Guide"
              element={<Class8দক্ষিণআমেরিকাPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-ওশিয়ানিয়া-Guide"
              element={<Class8ওশিয়ানিয়াPage />}
            />
            <Route
              exact
              path="/ভূগোল-ক্লাস-৮-First-Unit-Test-Guide"
              element={<Class8GeographyFirstUnitTestPage />}
            />
            <Route
              exact
              path="/English-Class-8-Guide"
              element={<Class8Englishক্লাস৮Page />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-Revision-lesson-Guide"
              element={<Class8RevisionlessonPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-The-Wind-Cap-Guide"
              element={<Class8TheWindCapPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-Clouds-Guide"
              element={<Class8CloudsPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-AN-APRIL-DAY-Guide"
              element={<Class8ANAPRILDAYPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-The-Great-Escape-Guide"
              element={<Class8TheGreatEscapePage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-Princess-September-Guide"
              element={<Class8PrincessSeptemberPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-The-Sea-Guide"
              element={<Class8TheSeaPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-A-Kings-Tale-Guide"
              element={<Class8AKingsTalePage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-The-Happy-Prince-Guide"
              element={<Class8TheHappyPrincePage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-Summer-Friends-Guide"
              element={<Class8SummerFriendsPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-Tales-of-Childhood-Guide"
              element={<Class8TalesofChildhoodPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-Midnight-Express-Guide"
              element={<Class8MidnightExpressPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-Someone-Guide"
              element={<Class8SomeonePage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-The-Man-Who-Planted-Trees-Guide"
              element={<Class8TheManWhoPlantedTreesPage />}
            />
            <Route
              exact
              path="/English-ক্লাস-৮-Text-book-all-writing-skill-Guide"
              element={<Class8TextbookallwritingskillPage />}
            />
            <Route
              exact
              path="/Life-Science-Class-8-Guide"
              element={<Class8জীবনবিজ্ঞানক্লাস৮Page />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-ক্লাস-৮-জীবদেহের-গঠন-Guide"
              element={<Class8জীবদেহেরগঠনPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-ক্লাস-৮-অণুজীবের-জগৎ-Guide"
              element={<Class8অণুজীবেরজগৎPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-ক্লাস-৮-মানুষের-খাদ্য-ও-খাদ্য-উপাদান-Guide"
              element={<Class8মানুষেরখাদ্যওখাদ্যউপাদানPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-ক্লাস-৮-অন্তঃক্ষরা-তন্ত্র-ও-বয়ঃসন্ধি-Guide"
              element={<Class8অন্তঃক্ষরাতন্ত্রওবয়ঃসন্ধিPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-ক্লাস-৮-জীববৈচিত্র্য-পরিবেশের-সংকট-ও-বিপন্ন-প্রাণী-সংরক্ষণ-Guide"
              element={
                <Class8জীববৈচিত্র্যপরিবেশেরসংকটওবিপন্নপ্রাণীসংরক্ষণPage />
              }
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-ক্লাস-৮-আমাদের-চারপাশের-পরিবেশ-ও-উদ্ভিদজগৎ-Guide"
              element={<Class8আমাদেরচারপাশেরপরিবেশওউদ্ভিদজগৎPage />}
            />
            <Route
              exact
              path="/জীবনবিজ্ঞান-ক্লাস-৮-First-Unit-Test-Guide"
              element={<Class8LifeScienceFirstUnitTestPage />}
            />
            <Route
              exact
              path="/Bengali-Grammar-Class-8-Guide"
              element={<Class8বাংলাব্যাকরণক্লাস৮Page />}
            />
            <Route
              exact
              path="/বাংলা-ব্যাকরণ-ক্লাস-৮-দল-Guide"
              element={<Class8দলPage />}
            />
            <Route
              exact
              path="/বাংলা-ব্যাকরণ-ক্লাস-৮-ধ্বনি-পরিবর্তনের-কারণ-ও-ধারা-Guide"
              element={<Class8ধ্বনিপরিবর্তনেরকারণওধারাPage />}
            />
            <Route
              exact
              path="/বাংলা-ব্যাকরণ-ক্লাস-৮-বাক্যের-ভাব-ও-রূপান্তর-Guide"
              element={<Class8বাক্যেরভাবওরূপান্তরPage />}
            />
            <Route
              exact
              path="/বাংলা-ব্যাকরণ-ক্লাস-৮-বিশেষ্য-বিশেষণ-সর্বনাম-অব্যয়-ও-ক্রিয়া-Guide"
              element={<Class8বিশেষ্যবিশেষণসর্বনামঅব্যয়ওক্রিয়াPage />}
            />
            <Route
              exact
              path="/বাংলা-ব্যাকরণ-ক্লাস-৮-ক্রিয়ার-কাল-ও-ক্রিয়ার-ভাব-Guide"
              element={<Class8ক্রিয়ারকালওক্রিয়ারভাবPage />}
            />
            {/* <Route
              exact
              path="/বাংলা-ব্যাকরণ-ক্লাস-৮-প্রবন্ধ-রচনা-Guide"
              element={<Class8প্রবন্ধরচনাPage />}
            /> */}
            <Route
              exact
              path="/বাংলা-ব্যাকরণ-ক্লাস-৮-সমাস-Guide"
              element={<Class8সমাসPage />}
            />
            <Route
              exact
              path="/History-Class-8-Guide"
              element={<Class8ইতিহাসক্লাস৮Page />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৮-আঞ্চলিক-শক্তির-উত্থান-Guide"
              element={<Class8আঞ্চলিকশক্তিরউত্থানPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৮-ঔপনিবেশিক-কর্তৃত্ব-প্রতিষ্ঠা-Guide"
              element={<Class8ঔপনিবেশিককর্তৃত্বপ্রতিষ্ঠাPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৮-ঔপনিবেশিক-অর্থনীতির-চরিত্র-Guide"
              element={<Class8ঔপনিবেশিকঅর্থনীতিরচরিত্রPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৮-ঔপনিবেশিক-শাসনের-প্রতিক্রিয়া-সহযোগিতা-ও-বিদ্রোহ-Guide"
              element={
                <Class8ঔপনিবেশিকশাসনেরপ্রতিক্রিয়াসহযোগিতাওবিদ্রোহPage />
              }
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৮-জাতীয়তাবাদের-প্রাথমিক-বিকাশ-Guide"
              element={<Class8জাতীয়তাবাদেরপ্রাথমিকবিকাশPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৮-ভারতের-জাতীয়-আন্দোলনের-আদর্শ-ও-বিবর্তন-Guide"
              element={<Class8ভারতেরজাতীয়আন্দোলনেরআদর্শওবিবর্তনPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৮-সাম্প্রদায়িকতা-থেকে-দেশভাগ-Guide"
              element={<Class8সাম্প্রদায়িকতাথেকেদেশভাগPage />}
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৮-ভারতীয়-সংবিধান-গণতন্ত্রের-কাঠামো-ও-জনগণের-অধিকার-Guide"
              element={
                <Class8ভারতীয়সংবিধানগণতন্ত্রেরকাঠামোওজনগণেরঅধিকারPage />
              }
            />
            <Route
              exact
              path="/ইতিহাস-ক্লাস-৮-First-Unit-Test-Guide"
              element={<Class8HistoryFirstUnitTestPage />}
            />

            <Route
              exact
              path="/West-Bengal-Class-12-Guide"
              element={<Class12Page />}
            />
            <Route
              exact
              path="/Bengali-Class-12-Guide"
              element={<Class12বাংলাউচ্চমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভাত-গল্প-Guide"
              element={<Class12ভাতগল্পPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-কে-বাঁচায়-কে-বাঁচে-Guide"
              element={<Class12কেবাঁচায়কেবাঁচেPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-রূপনারায়ণের-কূলে-Guide"
              element={<Class12রূপনারায়ণেরকূলেPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-শিকার-Guide"
              element={<Class12শিকারPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-আমি-দেখি-Guide"
              element={<Class12আমিদেখিPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-মহুয়ার-দেশ-Guide"
              element={<Class12মহুয়ারদেশPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-ক্রন্দনরতা-জননীর-পাশে-Guide"
              element={<Class12ক্রন্দনরতাজননীরপাশেPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বিভাব-নাটক-Guide"
              element={<Class12বিভাবনাটকPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-নানা-রঙের-দিন-নাটক-Guide"
              element={<Class12নানারঙেরদিননাটকPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-পড়তে-জানে-এমন-এক-মজুরের-প্রশ্ন-Guide"
              element={<Class12পড়তেজানেএমনএকমজুরেরপ্রশ্নPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-অলৌকিক-Guide"
              element={<Class12অলৌকিকPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-আমার-বাংলা-Guide"
              element={<Class12আমারবাংলাPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভারতবর্ষ-Guide"
              element={<Class12ভারতবর্ষPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-ধ্বনিতত্ব-Guide"
              element={<Class12ধ্বনিতত্বPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভাষাবিজ্ঞান-ও-তার-শাখা-প্রশাখা-Guide"
              element={<Class12ভাষাবিজ্ঞানওতারশাখাপ্রশাখাPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাক্যতত্ত্ব-ভাষা-Guide"
              element={<Class12বাক্যতত্ত্বভাষাPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-শব্দার্থ-তত্ত্ব-ভাষা-Guide"
              element={<Class12শব্দার্থতত্ত্বভাষাPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-রূপতত্ত্ব-ভাষা-Guide"
              element={<Class12রূপতত্ত্বভাষাPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাংলা-গানের-ধারা-Guide"
              element={<Class12বাংলাগানেরধারাPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাঙালির-চিত্রকলা-Guide"
              element={<Class12বাঙালিরচিত্রকলাPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাংলা-চলচ্চিত্রের-কথা-Guide"
              element={<Class12বাংলাচলচ্চিত্রেরকথাPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাঙালির-বিজ্ঞানচর্চা-Guide"
              element={<Class12বাঙালিরবিজ্ঞানচর্চাPage />}
            />
            <Route
              exact
              path="/বাংলা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাঙালির-ক্রীরাসংস্কৃতি-Guide"
              element={<Class12বাঙালিরক্রীরাসংস্কৃতিPage />}
            />

            <Route
              exact
              path="/English-Class-12-Guide"
              element={<Class12Englishউচ্চমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-The-Eyes-Have-It-Guide"
              element={<Class12TheEyesHaveItPage />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-Strong-Roots-Guide"
              element={<Class12StrongRootsPage />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-Thank-you-maam-Guide"
              element={<Class12ThankyoumaamPage />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-Three-Questions-Guide"
              element={<Class12ThreeQuestionsPage />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-On-Killing-A-Tree-Guide"
              element={<Class12OnKillingATreePage />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-Asleep-In-The-Valley-Guide"
              element={<Class12AsleepInTheValleyPage />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-Shall-I-Compare-Thee-Guide"
              element={<Class12ShallICompareTheePage />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-The-Poetry-of-Earth-Guide"
              element={<Class12ThePoetryofEarthPage />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-The-Proposal-Guide"
              element={<Class12TheProposalPage />}
            />
            {/* <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-Writing-Skills-Guide"
              element={<Class12WritingSkillsPage />}
            /> */}
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-Previous-Year-Question-Paper-2017-Guide"
              element={<Class12PreviousYearQuestionPaper2017Page />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-Previous-Year-Question-Paper-2018-Guide"
              element={<Class12PreviousYearQuestionPaper2018Page />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-Previous-Year-Question-Paper-2019-Guide"
              element={<Class12PreviousYearQuestionPaper2019Page />}
            />
            <Route
              exact
              path="/English-Higher-Secondary-Exam-Preparation-Previous-Year-Question-Paper-2020-Guide"
              element={<Class12PreviousYearQuestionPaper2020Page />}
            />
            <Route
              exact
              path="/Sanskrit-Class-12-Guide"
              element={<Class12সংস্কৃতউচ্চমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-বনগতা-গুহা-Guide"
              element={<Class12বনগতাগুহাPage />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-শ্রীমদ্ভাগবদ্গীতা-কর্মযোগ-Guide"
              element={<Class12শ্রীমদ্ভাগবদ্গীতাকর্মযোগPage />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-আর্যাবর্তবর্ণনম্-Guide"
              element={<Class12আর্যাবর্তবর্ণনম্Page />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-শ্রীগঙ্গাস্তোত্রম্-Guide"
              element={<Class12শ্রীগঙ্গাস্তোত্রম্Page />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাসন্তিকস্বপ্নম্-Guide"
              element={<Class12বাসন্তিকস্বপ্নম্Page />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-সংস্কৃত-সাহিত্যের-ইতিহাস-Guide"
              element={<Class12সংস্কৃতসাহিত্যেরইতিহাসPage />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভাবসম্প্রসারণ-Guide"
              element={<Class12ভাবসম্প্রসারণPage />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-সমাস-Guide"
              element={<Class12সমাসPage />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-শব্দযুগলের-অর্থ-পার্থক্য-Guide"
              element={<Class12শব্দযুগলেরঅর্থপার্থক্যPage />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-প্রত্যয়-Guide"
              element={<Class12প্রত্যয়Page />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাংলা-থেকে-সংস্কৃতে-অনুবাদ-Guide"
              element={<Class12বাংলাথেকেসংস্কৃতেঅনুবাদPage />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-অনুচ্ছেদ-রচনা-Guide"
              element={<Class12অনুচ্ছেদরচনাPage />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2017-Guide"
              element={<Class12SanskritPreviousyearQuestionpaper2017Page />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2018-Guide"
              element={<Class12SanskritPreviousyearQuestionpaper2018Page />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2019-Guide"
              element={<Class12SanskritPreviousyearQuestionpaper2019Page />}
            />
            <Route
              exact
              path="/সংস্কৃত-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2022-Guide"
              element={<Class12SanskritPreviousyearQuestionpaper2022Page />}
            />
            <Route
              exact
              path="/Geography-Class-12-Guide"
              element={<Class12ভূগোলউচ্চমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভূমিরূপ-গঠনকারী-প্রক্রিয়াসমূহ-বহিজাত-প্রক্রিয়াসমূহ-ও-সংশ্লিষ্ট-ভূমিরূপ-Guide"
              element={
                <Class12ভূমিরূপগঠনকারীপ্রক্রিয়াসমূহবহিজাতপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপPage />
              }
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভৌমজলের-কার্য-এবং-সংশ্লিষ্ট-ভূমিরূপ-Guide"
              element={<Class12ভৌমজলেরকার্যএবংসংশ্লিষ্টভূমিরূপPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-সামুদ্রিক-প্রক্রিয়াসমূহ-ও-সংশ্লিষ্ট-ভূমিরূপ-Guide"
              element={<Class12সামুদ্রিকপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-ক্ষয়চক্র-গঠন-ও-প্রক্রিয়া-Guide"
              element={<Class12ক্ষয়চক্রগঠনওপ্রক্রিয়াPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-জলনির্গম-প্রণালী-Guide"
              element={<Class12জলনির্গমপ্রণালীPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-মৃত্তিকা-Guide"
              element={<Class12মৃত্তিকাPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-বায়ুমণ্ডল-Guide"
              element={<Class12বায়ুমণ্ডলPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-জলবায়ু-পরিবর্তন-Guide"
              element={<Class12জলবায়ুপরিবর্তনPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-মানুষ-পরিবেশ-আন্তঃসম্পর্ক-প্রাকৃতিক-দুর্যোগ-ও-বিপর্যয়-Guide"
              element={
                <Class12মানুষপরিবেশআন্তঃসম্পর্কপ্রাকৃতিকদুর্যোগওবিপর্যয়Page />
              }
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-অর্থনৈতিক-ক্রিয়াকলাপ-Guide"
              element={<Class12অর্থনৈতিকক্রিয়াকলাপPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-প্রাথমিক-ক্রিয়াকলাপ-কৃষিকাজ-Guide"
              element={<Class12প্রাথমিকক্রিয়াকলাপকৃষিকাজPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-দ্বিতীয়-স্তরের-ক্রিয়াকলাপ-শিল্প-Guide"
              element={<Class12দ্বিতীয়স্তরেরক্রিয়াকলাপশিল্পPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-তৃতীয়-স্তরের-ক্রিয়াকলাপ-Guide"
              element={<Class12তৃতীয়স্তরেরক্রিয়াকলাপPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-চতুর্থ-স্তরের-ক্রিয়াকলাপ-Guide"
              element={<Class12চতুর্থস্তরেরক্রিয়াকলাপPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-পঞ্চম-স্তরের-ক্রিয়াকলাপ-Guide"
              element={<Class12পঞ্চমস্তরেরক্রিয়াকলাপPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-জনসংখ্যা-ও-জনবসতি-Guide"
              element={<Class12জনসংখ্যাওজনবসতিPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-আঞ্চলিক-অর্থনৈতিক-উন্নয়ন-Guide"
              element={<Class12আঞ্চলিকঅর্থনৈতিকউন্নয়নPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-জীববৈচিত্র্য-Guide"
              element={<Class12জীববৈচিত্র্যPage />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2017-Guide"
              element={<Class12GeographyPreviousyearQuestionpaper2017Page />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2018-Guide"
              element={<Class12GeographyPreviousyearQuestionpaper2018Page />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2019-Guide"
              element={<Class12GeographyPreviousyearQuestionpaper2019Page />}
            />
            <Route
              exact
              path="/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2022-Guide"
              element={<Class12GeographyPreviousyearQuestionpaper2022Page />}
            />
            <Route
              exact
              path="/Life-Science-Class-12-Guide"
              element={<Class12জীববিদ্যাউচ্চমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-জীবের-জনন-Guide"
              element={<Class12জীবেরজননPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-সপুষ্পক-উদ্ভিদের-যৌন-জনন-Guide"
              element={<Class12সপুষ্পকউদ্ভিদেরযৌনজননPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-মানুষের-জনন-Guide"
              element={<Class12মানুষেরজননPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-জননগত-স্বাস্থ্য-Guide"
              element={<Class12জননগতস্বাস্থ্যPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বংশগতি-ও-বিভেদ-Guide"
              element={<Class12বংশগতিওবিভেদPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বংশগতির-আণবিক-ভিত্তি-Guide"
              element={<Class12বংশগতিরআণবিকভিত্তিPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-অভিব্যক্তি-বা-বিবর্তন-Guide"
              element={<Class12অভিব্যক্তিবাবিবর্তনPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-অভিব্যক্তির-কৌশল-Guide"
              element={<Class12অভিব্যক্তিরকৌশলPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-স্বাস্থ্য-ও-রোগ-Guide"
              element={<Class12স্বাস্থ্যওরোগPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-খাদ্য-উৎপাদনের-উন্নতি-সাধন-Guide"
              element={<Class12খাদ্যউৎপাদনেরউন্নতিসাধনPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-মানবকল্যাণে-অণুজীব-Guide"
              element={<Class12মানবকল্যাণেঅণুজীবPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-জীব-প্রযুক্তি-এবং-এর-প্রয়োগ-Guide"
              element={<Class12জীবপ্রযুক্তিএবংএরপ্রয়োগPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাস্তুবিদ্যা-পরিবেশ-ও-পপুলেশন-Guide"
              element={<Class12বাস্তুবিদ্যাপরিবেশওপপুলেশনPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাস্তুতন্ত্র-Guide"
              element={<Class12বাস্তুতন্ত্রPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-জীববৈচিত্র্য-ও-সংরক্ষণ-Guide"
              element={<Class12জীববৈচিত্র্যওসংরক্ষণPage />}
            />
            <Route
              exact
              path="/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-পরিবেশ-সংক্রান্ত-বিষয়সমূহ-Guide"
              element={<Class12পরিবেশসংক্রান্তবিষয়সমূহPage />}
            />
            <Route
              exact
              path="/Philosophy-Class-12-Guide"
              element={<Class12দর্শনউচ্চমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-যুক্তি-Guide"
              element={<Class12যুক্তিPage />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-বচন-Guide"
              element={<Class12বচনPage />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-বচনের-বিরোধিতা-Guide"
              element={<Class12বচনেরবিরোধিতাPage />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-অমাধ্যম-অনুমান-Guide"
              element={<Class12অমাধ্যমঅনুমানPage />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-নিরপেক্ষ-ন্যায়-Guide"
              element={<Class12নিরপেক্ষন্যায়Page />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-প্রাকল্পিকও-বৈকল্পিক-ন্যায়-Guide"
              element={<Class12প্রাকল্পিকওবৈকল্পিকন্যায়Page />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-বুলীয়-ভাষ্য-ও-ভেনচিত্র-Guide"
              element={<Class12বুলীয়ভাষ্যওভেনচিত্রPage />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-সত্যাপেক্ষক-Guide"
              element={<Class12সত্যাপেক্ষকPage />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-আরোহ-অনুমানের-স্বরূপ-Guide"
              element={<Class12আরোহঅনুমানেরস্বরূপPage />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-কারণ-Guide"
              element={<Class12কারণPage />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-মিলের-পরীক্ষামূলক-পদ্ধতি-Guide"
              element={<Class12মিলেরপরীক্ষামূলকপদ্ধতিPage />}
            />
            <Route
              exact
              path="/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-আরোহমূলক-দোষ-Guide"
              element={<Class12আরোহমূলকদোষPage />}
            />
            <Route
              exact
              path="/Political-Science-Class-12-Guide"
              element={<Class12রাষ্ট্রবিজ্ঞানউচ্চমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-আন্তর্জাতিক-সম্পর্ক-Guide"
              element={<Class12আন্তর্জাতিকসম্পর্কPage />}
            />
            <Route
              exact
              path="/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-দ্বিতীয়-বিশ্বযুদ্ধের-পরবর্তী-পর্বের-আন্তর্জাতিক-সম্পর্ক-Guide"
              element={
                <Class12দ্বিতীয়বিশ্বযুদ্ধেরপরবর্তীপর্বেরআন্তর্জাতিকসম্পর্কPage />
              }
            />
            <Route
              exact
              path="/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-বিদেশনীতি-বা-পররাষ্ট্রনীতি-Guide"
              element={<Class12বিদেশনীতিবাপররাষ্ট্রনীতিPage />}
            />
            <Route
              exact
              path="/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-সম্মিলিত-জাতিপুঞ্জ-Guide"
              element={<Class12সম্মিলিতজাতিপুঞ্জPage />}
            />
            <Route
              exact
              path="/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-কয়েকটি-প্রধান-রাজনৈতিক-মতবাদ-Guide"
              element={<Class12কয়েকটিপ্রধানরাজনৈতিকমতবাদPage />}
            />
            <Route
              exact
              path="/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-সরকারের-বিভিন্ন-বিভাগ-Guide"
              element={<Class12সরকারেরবিভিন্নবিভাগPage />}
            />
            <Route
              exact
              path="/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভারতের-শাসন-বিভাগ-Guide"
              element={<Class12ভারতেরশাসনবিভাগPage />}
            />
            <Route
              exact
              path="/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভারতের-আইন-বিভাগ-Guide"
              element={<Class12ভারতেরআইনবিভাগPage />}
            />
            <Route
              exact
              path="/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভারতের-বিচার-বিভাগ-Guide"
              element={<Class12ভারতেরবিচারবিভাগPage />}
            />
            <Route
              exact
              path="/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-স্থানীয়-স্বায়ত্বশাসন-ব্যবস্থা-Guide"
              element={<Class12স্থানীয়স্বায়ত্বশাসনব্যবস্থাPage />}
            />
            <Route
              exact
              path="/Pedagogy-Class-12-Guide"
              element={<Class12শিক্ষাবিজ্ঞানউচ্চমাধ্যমিকপ্রস্তুতিPage />}
            />
            {/* <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-শিখন-Guide"
              element={<Class12শিখনPage />}
            /> */}
            <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-শিখনের-কৌশল-Guide"
              element={<Class12শিখনেরকৌশলPage />}
            />
            <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-শিক্ষায়-রাশিবিজ্ঞান-Guide"
              element={<Class12শিক্ষায়রাশিবিজ্ঞানPage />}
            />
            {/* <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভারতীয়-সংবিধানে-উল্লিখিতশিক্ষা-সম্পর্কে-বিভিন্ন-ধারা-Guide"
              element={
                <Class12ভারতীয়সংবিধানেউল্লিখিতশিক্ষাসম্পর্কেবিভিন্নধারাPage />
              }
            /> */}
            {/* <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-বিশ্ববিদ্যালয়-শিক্ষা-কমিশন-1948-49-Guide"
              element={<Class12বিশ্ববিদ্যালয়শিক্ষাকমিশন194849Page />}
            />
            <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-মাধ্যমিক-শিক্ষা-কমিশন-1952-53-Guide"
              element={<Class12মাধ্যমিকশিক্ষাকমিশন195253Page />}
            /> */}
            {/* <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভারতীয়-শিক্ষা-কমিশন-Guide"
              element={<Class12ভারতীয়শিক্ষাকমিশনPage />}
            /> */}
            <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-জাতীয়-শিক্ষানীতি-Guide"
              element={<Class12জাতীয়শিক্ষানীতিPage />}
            />
            <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-ব্যতিক্রমধর্মী-শিশুর-শিক্ষা-Guide"
              element={<Class12ব্যতিক্রমধর্মীশিশুরশিক্ষাPage />}
            />
            {/* <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-প্রাথমিক-শিক্ষার-সর্বজনীনতা-Guide"
              element={<Class12প্রাথমিকশিক্ষারসর্বজনীনতাPage />}
            /> */}
            <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-শিক্ষার-বিশ্বব্যাপী-দৃষ্টিভঙ্গি-Guide"
              element={<Class12শিক্ষারবিশ্বব্যাপীদৃষ্টিভঙ্গিPage />}
            />
            <Route
              exact
              path="/History-Class-12-Guide"
              element={<Class12ইতিহাসউচ্চমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/History-Higher-Secondary-Exam-Preparation-atit-ke-swaron-guide"
              element={<Class12অতীতকেস্মরণPage />}
            />
            <Route
              exact
              path="/History-Higher-Secondary-Exam-Preparation-ounobingso-o-bingso-shotoke-ouponibeshikotabad-o-samrajyobader-prosar-guide"
              element={
                <Class12ঊনবিংশওবিংশশতকেঔপনিবেশিকতাবাদওসাম্রাজ্যবাদেরপ্রসারPage />
              }
            />
            <Route
              exact
              path="/History-Higher-Secondary-Exam-Preparation-ouponivesik-adhipotyer-prokriti-niyomito-o-aniyomito-samrajya-guide"
              element={
                <Class12ঔপনিবেশিকআধিপত্যেরপ্রকৃতিনিয়মিতওঅনিয়মিতসাম্রাজ্যPage />
              }
            />
            <Route
              exact
              path="/History-Higher-Secondary-Exam-Preparation-samrajya-bader-biruddhe-protokriya-guide"
              element={<Class12সাম্রাজ্যবাদেরবিরুদ্ধেপ্রতিক্রিয়াPage />}
            />
            <Route
              exact
              path="/History-Higher-Secondary-Exam-Preparation-aupniveshik-bharater-shasan-guide"
              element={<Class12ঔপনিবেশিকভারতেরশাসনPage />}
            />
            <Route
              exact
              path="/History-Higher-Secondary-Exam-Preparation-ditio-biswayudha-o-ouponiveshik-somuho-guide"
              element={<Class12দ্বিতীয়বিশ্বযুদ্ধওউপনিবেশসমূহPage />}
            />
            <Route
              exact
              path="/History-Higher-Secondary-Exam-Preparation-thanda-lorayer-yug-guide"
              element={<Class12ঠান্ডালড়াইয়েরযুগPage />}
            />
            <Route
              exact
              path="/History-Higher-Secondary-Exam-Preparation-obo-ouponiveshikoron-guide"
              element={<Class12অবউপনিবেশীকরণPage />}
            />
            <Route
              exact
              path="/Computer-Class-12-Guide"
              element={<Class12Computerউচ্চমাধ্যমিকপ্রস্তুতিPage />}
            />
            <Route
              exact
              path="/Computer-Higher-Secondary-Exam-Preparation-Logic-Gate-and-Combinational-Circuit-Guide"
              element={<Class12LogicGateandCombinationalCircuitPage />}
            />
            <Route
              exact
              path="/Computer-Higher-Secondary-Exam-Preparation-Networking-Guide"
              element={<Class12NetworkingPage />}
            />
            <Route
              exact
              path="/Computer-Higher-Secondary-Exam-Preparation-Database-Management-System-Guide"
              element={<Class12DatabaseManagementSystemPage />}
            />
            <Route
              exact
              path="/Computer-Higher-Secondary-Exam-Preparation-MS-Excel-2007-Guide"
              element={<Class12MSExcel2007Page />}
            />
            <Route
              exact
              path="/Computer-Higher-Secondary-Exam-Preparation-MS-Access-2007-Guide"
              element={<Class12MSAccess2007Page />}
            />
            <Route
              exact
              path="/Articles-Guide"
              element={<EnglishGrammarArticlesHomePage />}
            />
            <Route
              exact
              path="/Articles-Articles-Guide"
              element={<EnglishGrammarArticlesPage />}
            />
            <Route
              exact
              path="/Articles-Beginner-Mock-Test-Guide"
              element={<EnglishGrammarBeginnerMockTestPage />}
            />
            <Route
              exact
              path="/Articles-Intermediate-Mock-Test-Guide"
              element={<EnglishGrammarIntermediateMockTestPage />}
            />
            <Route
              exact
              path="/Articles-Advanced-Mock-Test-Guide"
              element={<EnglishGrammarAdvancedMockTestPage />}
            />
            <Route
              exact
              path="/Gender-Guide"
              element={<EnglishGrammarGenderPage />}
            />
            <Route
              exact
              path="/Gender-Introduction-Guide"
              element={<EnglishGrammarIntroductionPage />}
            />
            <Route
              exact
              path="/Gender-Masculine-Guide"
              element={<EnglishGrammarMasculinePage />}
            />
            <Route
              exact
              path="/Gender-Feminine-Guide"
              element={<EnglishGrammarFemininePage />}
            />
            <Route
              exact
              path="/Gender-Neuter-Guide"
              element={<EnglishGrammarNeuterPage />}
            />
            <Route
              exact
              path="/Gender-Common-Guide"
              element={<EnglishGrammarCommonPage />}
            />
            <Route
              exact
              path="/Gender-Rules-To-Change-Gender-Guide"
              element={<EnglishGrammarRulesToChangeGenderPage />}
            />
            <Route
              exact
              path="/Gender-Beginner-Mock-Test-Guide"
              element={<EnglishGrammarGenderBeginnerMockTestPage />}
            />
            <Route
              exact
              path="/Gender-Intermediate-Mock-Test-Guide"
              element={<EnglishGrammarGenderIntermediateMockTestPage />}
            />
            <Route
              exact
              path="/Gender-Advanced-Mock-Test-Guide"
              element={<EnglishGrammarGenderAdvancedMockTestPage />}
            />

            <Route
              exact
              path="/Punctuation-Guide"
              element={<EnglishGrammarPunctuationPage />}
            />
            <Route
              exact
              path="/Punctuation-Introduction-Guide"
              element={<EnglishGrammarPunctuationIntroductionPage />}
            />
            <Route
              exact
              path="/Vocabulary-Guide"
              element={<EnglishGrammarVocabularyPage />}
            />
            <Route
              exact
              path="/Vocabulary-Subject-and-Predicate-Guide"
              element={<EnglishGrammarSubjectandPredicatePage />}
            />
            <Route
              exact
              path="/Vocabulary-Synonyms-and-Antonyms-Guide"
              element={<EnglishGrammarSynonymsandAntonymsPage />}
            />
            <Route
              exact
              path="/Vocabulary-Vowels-and-Consonants-Guide"
              element={<EnglishGrammarVowelsandConsonantsPage />}
            />
            <Route
              exact
              path="/Vocabulary-Silent-Letters-Guide"
              element={<EnglishGrammarSilentLettersPage />}
            />
            <Route
              exact
              path="/Vocabulary-Syllables-Guide"
              element={<EnglishGrammarSyllablesPage />}
            />
            <Route
              exact
              path="/Vocabulary-Collocations-Guide"
              element={<EnglishGrammarCollocationsPage />}
            />
            <Route
              exact
              path="/Vocabulary-Contracted-Forms-Guide"
              element={<EnglishGrammarContractedFormsPage />}
            />
            <Route
              exact
              path="/Sentence-Guide"
              element={<EnglishGrammarSentencePage />}
            />
            <Route
              exact
              path="/Sentence-Introduction-Guide"
              element={<EnglishGrammarSentenceIntroductionPage />}
            />
            <Route
              exact
              path="/Sentence-Simple-Sentence-Guide"
              element={<EnglishGrammarSimpleSentencePage />}
            />
            <Route
              exact
              path="/Sentence-Complex-Sentence-Guide"
              element={<EnglishGrammarComplexSentencePage />}
            />
            <Route
              exact
              path="/Sentence-Compound-Sentence-Guide"
              element={<EnglishGrammarCompoundSentencePage />}
            />
            <Route
              exact
              path="/Sentence-Compound-complex-Sentence-Guide"
              element={<EnglishGrammarCompoundcomplexSentencePage />}
            />
            <Route
              exact
              path="/Sentence-Assertive-Sentence-Guide"
              element={<EnglishGrammarAssertiveSentencePage />}
            />
            <Route
              exact
              path="/Sentence-Imperative-Sentence-Guide"
              element={<EnglishGrammarImperativeSentencePage />}
            />
            <Route
              exact
              path="/Sentence-Operative-Sentence-Guide"
              element={<EnglishGrammarOperativeSentencePage />}
            />
            <Route
              exact
              path="/Sentence-Exclamatory-Sentence-Guide"
              element={<EnglishGrammarExclamatorySentencePage />}
            />
            <Route
              exact
              path="/Sentence-Interrogative-Sentence-Guide"
              element={<EnglishGrammarInterrogativeSentencePage />}
            />
            <Route
              exact
              path="/Sentence-Beginner-Mock-Test-Guide"
              element={<EnglishGrammarSentenceBeginnerMockTestPage />}
            />
            <Route
              exact
              path="/Sentence-Intermediate-Mock-Test-Guide"
              element={<EnglishGrammarSentenceIntermediateMockTestPage />}
            />
            <Route
              exact
              path="/Sentence-Advanced-Mock-Test-Guide"
              element={<EnglishGrammarSentenceAdvancedMockTestPage />}
            />
            <Route
              exact
              path="/Nouns-Guide"
              element={<EnglishGrammarNounsPage />}
            />
            <Route
              exact
              path="/Nouns-Introduction-Guide"
              element={<EnglishGrammarNounsIntroductionPage />}
            />
            <Route
              exact
              path="/Nouns-Proper-Noun-Guide"
              element={<EnglishGrammarProperNounPage />}
            />
            <Route
              exact
              path="/Nouns-Common-Noun-Guide"
              element={<EnglishGrammarCommonNounPage />}
            />
            <Route
              exact
              path="/Nouns-Collective-Noun-Guide"
              element={<EnglishGrammarCollectiveNounPage />}
            />
            <Route
              exact
              path="/Nouns-Material-Noun-Guide"
              element={<EnglishGrammarMaterialNounPage />}
            />
            <Route
              exact
              path="/Nouns-Abstract-Noun-Guide"
              element={<EnglishGrammarAbstractNounPage />}
            />
            <Route
              exact
              path="/Nouns-Countable-Noun-Guide"
              element={<EnglishGrammarCountableNounPage />}
            />
            <Route
              exact
              path="/Nouns-Uncountable-Noun-Guide"
              element={<EnglishGrammarUncountableNounPage />}
            />
            <Route
              exact
              path="/Nouns-Beginner-Mock-Test-Guide"
              element={<EnglishGrammarNounsBeginnerMockTestPage />}
            />
            <Route
              exact
              path="/Nouns-Intermediate-Mock-Test-Guide"
              element={<EnglishGrammarNounsIntermediateMockTestPage />}
            />
            <Route
              exact
              path="/Nouns-Advanced-Mock-Test-Guide"
              element={<EnglishGrammarNounsAdvancedMockTestPage />}
            />
            <Route
              exact
              path="/Adjectives-Guide"
              element={<EnglishGrammarAdjectivesPage />}
            />
            <Route
              exact
              path="/Adjectives-Introduction-Guide"
              element={<EnglishGrammarAdjectivesIntroductionPage />}
            />
            <Route
              exact
              path="/Adjectives-Descriptive-Adjectives-Guide"
              element={<EnglishGrammarDescriptiveAdjectivesPage />}
            />
            <Route
              exact
              path="/Adjectives-Quantitative-Adjectives-Guide"
              element={<EnglishGrammarQuantitativeAdjectivesPage />}
            />
            <Route
              exact
              path="/Adjectives-Demonstrative-Adjectives-Guide"
              element={<EnglishGrammarDemonstrativeAdjectivesPage />}
            />
            <Route
              exact
              path="/Adjectives-Possessive-Adjectives-Guide"
              element={<EnglishGrammarPossessiveAdjectivesPage />}
            />
            <Route
              exact
              path="/Adjectives-Interrogative-Adjectives-Guide"
              element={<EnglishGrammarInterrogativeAdjectivesPage />}
            />
            <Route
              exact
              path="/Adjectives-Numeral-Adjectives-Guide"
              element={<EnglishGrammarNumeralAdjectivesPage />}
            />
            <Route
              exact
              path="/Adjectives-Exclamatory-Adjectives-Guide"
              element={<EnglishGrammarExclamatoryAdjectivesPage />}
            />
            <Route
              exact
              path="/Adjectives-Beginner-Mock-Test-Guide"
              element={<EnglishGrammarAdjectivesBeginnerMockTestPage />}
            />
            <Route
              exact
              path="/Adjectives-Intermediate-Mock-Test-Guide"
              element={<EnglishGrammarAdjectivesIntermediateMockTestPage />}
            />
            <Route
              exact
              path="/Adjectives-Advanced-Mock-Test-Guide"
              element={<EnglishGrammarAdjectivesAdvancedMockTestPage />}
            />
            {/* <Route
              exact
              path="/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-শিক্ষা-প্রযুক্তিবিদ্যা-Guide"
              element={<Class12শিক্ষাপ্রযুক্তিবিদ্যাPage />}
            /> */}
            {/* <Route index element={<HomePage />} />
              <Route path="about" element={<HomePage />} />
              <Route path="dashboard" element={<HomePage />} />
              <Route path="*" element={<HomePage />} /> */}
          </Routes>
        </Router>
        <FooterComponent />
      </Container>
    </ThemeProvider>
  );
}

export default App;
